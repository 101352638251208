import Constants from "expo-constants";

export const API_GATEWAY_BASE_URL =
  Constants.manifest?.extra?.API_GATEWAY_BASE_URL;

export const MAPBOX_ACCESS_TOKEN =
  Constants.manifest?.extra?.MAPBOX_ACCESS_TOKEN;

export const COGNITO_REGION = Constants.manifest?.extra?.COGNITO_REGION;

export const COGNITO_USER_POOL_ID =
  Constants.manifest?.extra?.COGNITO_USER_POOL_ID;

export const COGNITO_USER_POOL_WEB_CLIENT_ID =
  Constants.manifest?.extra?.COGNITO_USER_POOL_WEB_CLIENT_ID;

export const CONFIG_ENGINE_BASE_URL =
  Constants.manifest?.extra?.CONFIG_ENGINE_BASE_URL;

export const ERROR_LOGGER = {
  dsn: Constants.manifest?.extra?.SENTRY_DSN,
};
export const ENVIRONMENT = Constants.manifest?.extra?.ENVIRONMENT;

export const AMPLI_ENVIRONMENT = Constants.manifest?.extra?.AMPLI_ENVIRONMENT;

export const PHARMACY_ID = Constants.manifest?.extra?.PHARMACY_ID;

export const PHARMACY_NAME = Constants.manifest?.extra?.PHARMACY_NAME;

export const USER_API_BASE_URL = Constants.manifest?.extra?.USER_API_BASE_URL;

export const PATIENT_API_BASE_URL =
  Constants.manifest?.extra?.PATIENT_API_BASE_URL;

export const PRESCRIPTION_API_BASE_URL =
  Constants.manifest?.extra?.PRESCRIPTION_API_BASE_URL;

export const ORDER_API_BASE_URL =
  Constants.manifest?.extra?.ORDER_API_BASE_URL;

export const PHARMACY_API_BASE_URL =
  Constants.manifest?.extra?.PHARMACY_API_BASE_URL;

export const FORMS_API_BASE_URL = Constants.manifest?.extra?.FORMS_API_BASE_URL;

export const STATIC_DEV_LUMISTRY =
  Constants.manifest?.extra?.STATIC_DEV_LUMISTRY;

export const VUCA_API_BASE_URL = Constants.manifest?.extra?.VUCA_API_BASE_URL;

export const APPOINTMENT_API_BASE_URL =
  Constants.manifest?.extra?.APPOINTMENT_API_BASE_URL;

export const UNIFIED_COMMS_SERVICE_BASE_URL =
  Constants.manifest?.extra?.UNIFIED_COMMS_SERVICE_BASE_URL;

export const GOOGLE_WEB_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_WEB_CLIENT_ID;
export const GOOGLE_EXPO_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_EXPO_CLIENT_ID;
export const GOOGLE_IOS_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_IOS_CLIENT_ID;
export const GOOGLE_ANDROID_CLIENT_ID =
  Constants.manifest?.extra?.GOOGLE_ANDROID_CLIENT_ID;

export const RETURN_URL_KEY = "returnUrl";
