import { Platform } from "react-native";
import * as Sentry from "sentry-expo";
import Heap from "@heap/react-native-heap";

export const logError = (error: Error) => {
  if (Platform.OS === "web") {
    Sentry.Browser.captureException(error);
  } else {
    Sentry.Native.captureException(error);
  }
  console.error(error);
};

export const logInfo = (info: string) => {
  if (Platform.OS === "web") {
    Sentry.Browser.captureMessage(info);
  } else {
    Sentry.Native.captureMessage(info);
  }
  console.info(info);
};

export const logEvent = (eventName: string, data?: {}) => {
  // log to Google Analytics
  // Analytics.logEvent(eventName, data);
  // TODO: will we be forced to log to snowplow? Only web is currently supported and getting native support would involve needing to be an expo plugin master
  //Heap.track(eventName, data); // commenting out because console throws errors since this only works on a built app
};

export const setCurrentScreen = async (screen: string | undefined) => {
  if (!screen) {
    return;
  }
  //logEvent("screen_view", { screen_name: screen }); // commenting out because console throws errors since this only works on a built app
};
