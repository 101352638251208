import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { CalendarIcon, ClockIcon, LocationIcon, UserIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import { formatDateTimeWithTimezone } from "../../../common/datetime-utils";
import { AppointmentInfo } from "./AppointmentInfo";
import { setStep } from "./book-appointment-actions";
import { useBookAppointmentState } from "./book-appointment-store";
import {
  APPOINTMENT_DATE_TIME_FORMAT,
  DEFAULT_UTC_OFFSET,
} from "./book-appointment-utils";
import {
  PatientRecordDto,
  RecordUnderCareDto,
} from "@digitalpharmacist/patient-service-client-axios";

export const BookAppointmentSummary: FunctionComponent<BookAppointmentSummaryProps> =
  (props) => {
    const {
      selectedSlot,
      appointmentType,
      selectedPatient,
      selectedLocation,
      isReschedule,
    } = useBookAppointmentState();
    const theme = useTheme();
    const styles = useStyles();

    const getPatientName = (patient: PatientRecordDto | RecordUnderCareDto) => {
      const isRecordUnderCare = "record_under_care" in patient;
      let record;

      if (isRecordUnderCare) {
        record = (patient as RecordUnderCareDto).record_under_care;
      } else {
        record = patient as PatientRecordDto;
      }

      return `${record.first_name} ${record.last_name} (${record.date_of_birth})`;
    };

    return (
      <View style={styles.container}>
        <Text style={styles.title}>{appointmentType?.title}</Text>
        <View style={styles.divider} />
        {selectedLocation && (
          <AppointmentInfo icon={LocationIcon}>
            <Text>{selectedLocation.name}</Text>
            {selectedLocation.address && (
              <>
                <Text style={{ color: theme.palette.gray[500] }}>
                  {selectedLocation.address.address1}
                  {selectedLocation.address.address2 &&
                    `, ${selectedLocation.address.address2}`}
                </Text>
                <Text style={{ color: theme.palette.gray[500] }}>
                  {selectedLocation.address.city},{" "}
                  {selectedLocation.address.state}{" "}
                  {selectedLocation.address.postal_code}
                </Text>
              </>
            )}
          </AppointmentInfo>
        )}
        <AppointmentInfo icon={ClockIcon}>
          <Text>
            {appointmentType?.length} {getText("minutes")}
          </Text>
        </AppointmentInfo>
        {selectedSlot && (
          <AppointmentInfo
            icon={CalendarIcon}
            onPress={() => setStep(isReschedule ? 0 : 1)}
            touchable={true}
          >
            <Text style={{ color: theme.palette.primary[600] }}>
              {formatDateTimeWithTimezone(
                selectedSlot.time,
                DEFAULT_UTC_OFFSET,
                APPOINTMENT_DATE_TIME_FORMAT
              )}
            </Text>
          </AppointmentInfo>
        )}
        {selectedPatient && (
          <View style={styles.patientSelectorWrapper}>
            <AppointmentInfo
              icon={UserIcon}
              touchable={!isReschedule}
              onPress={() => setStep(0)}
            >
              <Text
                style={!isReschedule && { color: theme.palette.primary[600] }}
              >
                {getPatientName(selectedPatient)}
              </Text>
            </AppointmentInfo>
          </View>
        )}
      </View>
    );
  };

export interface BookAppointmentSummaryProps {}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: theme.palette.gray[700],
    textAlign: "center",
  },
  divider: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  checkboxWrapper: {
    marginVertical: theme.getSpacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  patientSelectorWrapper: {
    marginTop: theme.getSpacing(1),
  },
}));
