import { BottomSheetModal } from "@gorhom/bottom-sheet";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { BottomSheet } from "assets/components/bottom-sheet";
import { IconButton } from "assets/components/icon-button";
import { Modal } from "assets/components/modal";
import { Text } from "assets/components/text";
import { CloseIcon } from "assets/icons";
import { makeStyles, useTheme } from "assets/theme";
import { getText } from "assets/localization/localization";
import { Button } from "assets/components/button";
import { useBookAppointmentState } from "./book-appointment-store";
import {
  createBooking,
  disableNextButton,
  resetBookAppointment,
  setProcessError,
  setShowBookAppointment,
  setStep,
  setLocation,
  updateBooking,
  clearLocation,
} from "./book-appointment-actions";
import { BookAppointmentSteps } from "./BookAppointmentSteps";
import {
  CreateBookingDto,
  UpdateBookingDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import { useUserState } from "../../../store/user-store";
import { formatDateTimeApi } from "../../../common/datetime-utils";
import moment from "moment";
import { useAppStateStore } from "../../../store/app-store";
import { refreshAppointmentsList } from "../appointments-actions";
import { refreshAppointmentDetails } from "../../appointment/appointment-details-actions";
import { AppointmentsScreenRouteProp } from "../../../navigation/RootNavigation";
import { RecordUnderCareDto } from "@digitalpharmacist/patient-service-client-axios";
import { AddToCalendar, AddToCalendarHandler } from "./AddToCalendar";
import { getMinutesBetweenDates } from "./book-appointment-utils";
import { BookAppointmentPatientRecord } from "./BookAppointmentPatientRecord";
import { BookAppointmentDate } from "./BookAppointmentDate";
import { BookAppointmentTime } from "./BookAppointmentTime";
import { BookAppointmentSummary } from "./BookAppointmentSummary";
import { BookAppointmentConfirmation } from "./BookAppointmentConfirmation";

export const BookAppointment: FunctionComponent<BookAppointmentProps> = ({
  onDismiss,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const sheetRef = useRef<BottomSheetModal>(null);
  const addToCalendarRef = useRef<AddToCalendarHandler>(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    step,
    nextButtonStatus,
    selectedSlot,
    bookingStatus,
    showBookAppointment,
    appointmentType,
    isReschedule,
    booking,
    location,
    processError,
    selectedPatient,
    selectedLocation,
    locationAppointmentTypeId,
  } = useBookAppointmentState((state) => ({
    ...state,
    locationAppointmentTypeId: state.availableAppointmentLocations.find(
      (item) => item.location_id === state.selectedLocation?.id
    )?.appointment_type_ids[0],
  }));
  const { user } = useUserState();
  const { pharmacyId, stores } = useAppStateStore();
  const navigation = useNavigation<AppointmentsScreenRouteProp>();

  useEffect(() => {
    if (bookingStatus === "success" || bookingStatus === "error") {
      setStep(step + 1);
    }
  }, [bookingStatus]);

  useEffect(() => {
    if (showBookAppointment) {
      Platform.OS === "web"
        ? setModalIsOpen(true)
        : sheetRef.current?.present();
    } else {
      Platform.OS === "web"
        ? setModalIsOpen(false)
        : sheetRef.current?.dismiss();
    }

    // check if there is a selected location for the book appointment screen
    if (showBookAppointment && !location && user?.preferredPharmacyLocationId) {
      // use preferred location as a fallback
      const findPreferredLocation = stores.find(
        (store) => store.id === user.preferredPharmacyLocationId
      );
      setLocation(findPreferredLocation!);
    }

    if (!showBookAppointment && location) {
      // clear configured location on modal close
      clearLocation();
    }
  }, [showBookAppointment]);

  const handleDismiss = () => {
    if (bookingStatus === "success") {
      refreshAppointmentsList();

      if (isReschedule) {
        refreshAppointmentDetails();
      }
    }

    resetBookAppointment();
    onDismiss();
  };

  const handleNextPress = () => {
    if (step < bookingSteps.length - 2) {
      setStep(step + 1);
    }

    if (step < bookingSteps.length - 3) {
      disableNextButton();
    }

    if (step === bookingSteps.length - 2) {
      handleBooking();
    }

    if (step === bookingSteps.length - 1) {
      setShowBookAppointment(false);
      handleDismiss();
    }

    if (processError) {
      setProcessError(false);
    }
  };

  const handleBackPress = () => {
    setStep(step - 1);

    if (processError) {
      setProcessError(false);
    }
  };

  const handleBooking = () => {
    if (isReschedule) {
      handleRescheduleBooking();
    } else {
      handleCreateBooking();
    }
  };

  const handleCreateBooking = () => {
    if (!selectedLocation || !selectedPatient || !locationAppointmentTypeId)
      return;

    const isRecordUnderCare = "record_under_care" in selectedPatient;

    const bookingData: CreateBookingDto = {
      appointment_type_id: locationAppointmentTypeId,
      patient_user_id: user!.id,
      patient_record_id: isRecordUnderCare
        ? (selectedPatient as RecordUnderCareDto).record_under_care.id
        : selectedPatient.id,
      title: appointmentType!.title,
      description: appointmentType!.description,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, "minutes")
      ),
      timeZone: "US/Central",
      submissions: [],
    };

    createBooking(pharmacyId, selectedLocation.id, bookingData);
  };

  const handleRescheduleBooking = () => {
    if (!booking || !selectedLocation) return;

    const bookingData: UpdateBookingDto = {
      ...booking,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, "minutes")
      ),
      timeZone: undefined,
    };

    updateBooking(pharmacyId, selectedLocation.id, booking.id, bookingData);
  };

  const getOkButtonText = () => {
    if (step === bookingSteps.length - 2) {
      return getText("confirm");
    }

    if (step === bookingSteps.length - 1) {
      return getText("done");
    }

    return getText("next");
  };

  const handleOnAddToCalendar = () => {
    addToCalendarRef.current?.show();
  };

  const bookingSteps = [
    <BookAppointmentPatientRecord navigation={navigation} />,
    <BookAppointmentDate />,
    <BookAppointmentTime />,
    <BookAppointmentSummary />,
    <BookAppointmentConfirmation
      onAddToCalendarPress={handleOnAddToCalendar}
    />,
  ];

  if (isReschedule) {
    bookingSteps.shift();
  }

  return (
    <>
      {Platform.OS === "web" ? (
        <Modal
          show={modalIsOpen}
          title={
            isReschedule
              ? getText("reschedule-appointment")
              : getText("book-appointment")
          }
          okButtonProps={{
            onPress: handleNextPress,
            logger: { id: "book-appointment-ok-button-modal" },
            text: getOkButtonText(),
            disabled: nextButtonStatus === "disabled",
            loading: bookingStatus === "loading",
          }}
          dismissButtonProps={{
            onPress: handleDismiss,
            logger: { id: "book-appointment-dismiss-button-modal" },
          }}
          cancelButtonProps={
            step > 0 && step < bookingSteps.length - 1
              ? {
                  onPress: handleBackPress,
                  text: getText("back"),
                  logger: { id: "book-appointment-back-button-modal" },
                }
              : undefined
          }
          isScrollable={true}
          height={800}
        >
          <BookAppointmentSteps
            navigation={navigation}
            bookingSteps={bookingSteps}
          />
        </Modal>
      ) : (
        <BottomSheet
          bottomSheetRef={sheetRef}
          height={"100%"}
          onDismiss={handleDismiss}
          hideHandle={true}
          contentContainerStyle={{ flexGrow: 1 }}
          headerContent={
            <View
              style={{
                position: "relative",
                marginBottom: theme.getSpacing(1),
              }}
            >
              <View style={styles.sheetTitleContainer}>
                <Text style={styles.sheetTitle}>
                  {isReschedule
                    ? getText("reschedule-appointment")
                    : getText("book-appointment")}
                </Text>
              </View>
              <View style={styles.sheetIconContainer}>
                <IconButton
                  icon={CloseIcon}
                  onPress={handleDismiss}
                  size={24}
                  color={theme.palette.gray[500]}
                  logger={{ id: "book-appointment-bottom-sheet-close" }}
                />
              </View>
            </View>
          }
          footerContent={
            <>
              {!processError && (
                <View style={styles.sheetButtons}>
                  {step > 0 && step < bookingSteps.length - 1 ? (
                    <Button
                      hierarchy="tertiary-gray"
                      size="medium"
                      onPress={() => setStep(step - 1)}
                      logger={{ id: `book-appointment-back` }}
                      style={styles.sheetButton}
                    >
                      {getText("back")}
                    </Button>
                  ) : null}
                  {step < bookingSteps.length - 1 ? (
                    <Button
                      hierarchy="primary"
                      size="medium"
                      onPress={handleNextPress}
                      logger={{ id: `book-appointment-next` }}
                      style={[
                        styles.sheetButton,
                        step === 0 && styles.sheetButtonFull,
                      ]}
                      disabled={nextButtonStatus === "disabled"}
                      loading={bookingStatus === "loading"}
                    >
                      {step === bookingSteps.length - 2
                        ? getText("confirm")
                        : getText("next")}
                    </Button>
                  ) : (
                    <Button
                      hierarchy="primary"
                      size="medium"
                      onPress={() => handleDismiss()}
                      logger={{ id: `book-appointment-done` }}
                      style={[styles.sheetButton, styles.sheetButtonFull]}
                    >
                      {getText("done")}
                    </Button>
                  )}
                </View>
              )}
            </>
          }
        >
          <BookAppointmentSteps
            navigation={navigation}
            bookingSteps={bookingSteps}
          />
        </BottomSheet>
      )}

      {booking && (
        <AddToCalendar
          ref={addToCalendarRef}
          title={`${booking.title} with ${selectedLocation?.name ?? ""}`}
          notes={booking.description}
          startDate={new Date(booking.startTime)}
          endDate={new Date(booking.endTime)}
          location={`${
            selectedLocation?.address?.address1
              ? selectedLocation.address.address1 + ","
              : ""
          } ${
            selectedLocation?.address?.city
              ? selectedLocation.address.city + ","
              : ""
          } ${
            selectedLocation?.address?.state
              ? selectedLocation.address.state + ","
              : ""
          } ${
            selectedLocation?.address?.postal_code
              ? selectedLocation.address.postal_code + ","
              : ""
          }`}
          durationInMinutes={getMinutesBetweenDates(
            booking.startTime,
            booking.endTime
          )}
        />
      )}
    </>
  );
};

export interface BookAppointmentProps {
  onDismiss: () => void;
}

const useStyles = makeStyles((theme) => ({
  sheetIconContainer: {
    position: "absolute",
    right: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
  },
  sheetButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: theme.getSpacing(2),
  },
  sheetButton: {
    marginBottom: theme.getSpacing(2),
    marginTop: "auto",
    width: "45%",
  },
  sheetButtonFull: {
    width: "100%",
  },
}));
