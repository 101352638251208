import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { makeStyles } from "assets/theme";
import { BottomSheet } from "assets/components/bottom-sheet";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text/Text";
import { useForm } from "assets/form";
import { getText } from "assets/localization/localization";

import { View } from "react-native";
import { Divider } from "react-native-paper";

import { PrescriptionDto } from "@digitalpharmacist/prescription-service-client-axios";
import { BottomSheetModal } from "@gorhom/bottom-sheet";

import { PrescriptionCard } from "../../../components/prescription-card";
import { useRefillMedicationsStore } from "../refill-store";
import { RefillStackParamList } from "../RefillNavigation";
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from "./UnableToSubmitProps";
import { UnableToSubmitTestID } from "./UnableToSubmitTestID";

import type { NativeStackScreenProps } from "@react-navigation/native-stack";
const UnableToSubmitBottomSheetComponent: ForwardRefRenderFunction<
  UnableToSubmitHandler,
  UnableToSubmitProps
> = (
  {
    onChange = (value: string) => {},
    disabled = false,
    handleUnableToSubmit = (value: string) => {},
  }: UnableToSubmitProps,
  ref: ForwardedRef<UnableToSubmitHandler>
) => {
  const styles = useStyles();
  const {
    selectedMedications,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();

  const methods = useForm({
    defaultValues: {
      handleNextRadioButton: "",
    },
  });
  const sheetRef = React.useRef<BottomSheetModal>(null);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const removeUnableToSubmitMedications = (item: PrescriptionDto) => {
    const newSelectedMedications = selectedMedications.filter(
      (med) => med.rx_number !== item.rx_number
    );
    const newUnableToSelectMedications = unableToSubmitMedications?.filter(
      (med) => med.rx_number !== item.rx_number
    );
    if (updateMedicationsData) {
      updateMedicationsData({
        selectedMedications: newSelectedMedications,
        unableToSubmitMedications: newUnableToSelectMedications,
      });
    }
  };

  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      title={"Notice"}
      height={"90%"}
      onDismiss={handleBottomSheetDismiss}
      children={
        <View>
          <Text style={styles.textTitle}>
            {getText("unable-to-process-items")}
          </Text>
          <Divider />
          <Text style={styles.textTitleDescription}>
            {getText("we-will-contact-prescriber")}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText("please-review-the-messages-below")}
          </Text>
          {unableToSubmitMedications?.map((medication, index) => (
            <PrescriptionCard
              prescription={medication}
              key={index}
              removable={true}
              showDetailsIcon={false}
              onRemove={() => removeUnableToSubmitMedications(medication)}
            />
          ))}
          <View>
            <Button
              hierarchy="primary"
              onPress={() => {
                handleUnableToSubmit("continue");
                handleBottomSheetDismiss();
              }}
              testID={UnableToSubmitTestID.submitButton}
              logger={{ id: UnableToSubmitTestID.submitButton }}
            >
              {getText("continue")}
            </Button>
          </View>
        </View>
      }
    />
  );
};


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: "500",
    fontSize: 14,
    marginTop: theme.getSpacing(3),
  },
}));

export const UnableToSubmitBottomSheet = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(UnableToSubmitBottomSheetComponent);
