import * as Sentry from "sentry-expo";

export const initLogger = (
  environment: string,
  errorLogger: { dsn?: string }
) => {
  const isDevelopment = environment === "development";
  if (!!errorLogger?.dsn) {
    Sentry.init({
      dsn: errorLogger.dsn,
      enableInExpoDevelopment: true,
      debug: isDevelopment,
      environment: environment,
    });
  }
};
