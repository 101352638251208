import { Button } from "assets/components/button";
import { CheckboxField } from "assets/components/checkbox";
import { Text } from "assets/components/text/Text";
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from "assets/components/typeahead-with-tags";
import { useForm } from "assets/form";
import { Form, ScreenContainer } from "assets/layout";
import { getText } from "assets/localization/localization";
import { useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { View } from "react-native";

import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { RefillGuestTestIDs } from "../../refill-guest/RefillGuestTestIDs";
import refillService from "../refill-service";
import {
  useRefillMedicalInformationStore,
  useRefillMedicationsStore,
} from "../refill-store";
import { RefillStackParamList } from "../RefillNavigation";
import { RefillTestIDs } from "../RefillTestIDs";
import patientService from "../../../api/patient-service";
import { useUserState } from "../../../store/user-store";
import {PatientRecordDto} from "@digitalpharmacist/patient-service-client-axios";

export const OtherInformationModal: FunctionComponent<
  PropsWithChildren<OtherInformationNavigationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const { user } = useUserState();
  const { patientMedicalInfos, updateMedicalInformationData } =
    useRefillMedicalInformationStore();
  const { selectedMedications, selectedPatientRecord, updateMedicationsData } =
    useRefillMedicationsStore();

  const form = useForm({
    defaultValues: { ...patientMedicalInfos },
  });

  useEffect(() => {
    if (!user?.patientRecordId || !selectedPatientRecord) return;

    const allergies =
      selectedPatientRecord.allergies === undefined
        ? []
        : selectedPatientRecord.allergies.length == 0
        ? [{ value: "no_allergies", text: "" }]
        : selectedPatientRecord.allergies.map((v) => ({ value: v, text: v }));

    form.setValue("gender", selectedPatientRecord.gender || "");
    form.setValue("allergies", allergies);
    form.setValue(
      "medicalConditions",
      selectedPatientRecord.medical_conditions.map((v) => ({
        value: v,
        text: v,
      }))
    );
    form.setValue(
      "easyBottleCaps",
      selectedPatientRecord.prefers_easy_open_bottle_caps || false
    );
    form.setValue(
      "prescriptionMonthly",
      selectedPatientRecord.prefers_med_sync ? "yes" : "no"
    );
  }, [selectedPatientRecord]);

  const handleOnPressDone = async () => {
    updateMedicalInformationData(form.getValues());

    if (!user?.patientRecordId) return;

    const patientMedicalInfos = {
      id: user?.patientRecordId,
      allergies: form
        .getValues()
        .allergies.map((v) => v.value)
        .filter((v) => v !== "no_allergies"),
      medical_conditions: form
        .getValues()
        .medicalConditions.map((v) => v.value),
      prefers_easy_open_bottle_caps: form.getValues().easyBottleCaps,
    };

    if (updateMedicationsData) {
      updateMedicationsData({
        selectedPatientRecord: {
          ...selectedPatientRecord,
          allergies: patientMedicalInfos.allergies,
          medical_conditions: patientMedicalInfos.medical_conditions,
          prefers_easy_open_bottle_caps: patientMedicalInfos.prefers_easy_open_bottle_caps
        } as PatientRecordDto,
        selectedMedications: selectedMedications,
      })
    }

    await patientService.updatePatientRecord(
      patientMedicalInfos.id,
      patientMedicalInfos
    );

    if (updateMedicationsData && selectedPatientRecord)
      updateMedicationsData({
        selectedPatientRecord: {
          ...selectedPatientRecord,
          ...patientMedicalInfos,
        },
        selectedMedications,
      });

    navigation.navigate("refill-review");
  };

  const allergiesFieldProps: TypeaheadWithTagsFieldProps = {
    name: "allergies",
    rules: { required: { value: true, message: "This field is required *" } },
    typeaheadWithTagsProps: {
      label: "Allergies *",
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchAllergies(searchTerm);
      },
      hintMessage: "Your allergies",
      emptyValue: { text: "No known allergies", value: "no_allergies" },
      tags: refillService.topAllergiesHints,
    },
  };

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    name: "medicalConditions",
    typeaheadWithTagsProps: {
      // label: "Medical conditions",
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: "Medical conditions",
      tags: refillService.topMedicalConditionsHints,
    },
  };

  return (
    <ScreenContainer style={{ marginTop: theme.getSpacing(2) }}>
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...allergiesFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={{ marginVertical: theme.getSpacing(1) }}>
              More information
            </Text>
            <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <View style={{ marginVertical: theme.getSpacing(1) }}>
              <CheckboxField
                label={getText("easy-open-bottle-caps")}
                testID={RefillTestIDs.allergiesCheckbox}
                name={"easyBottleCaps"}
              />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            hierarchy="primary"
            onPress={form.handleSubmit(handleOnPressDone)}
            testID={RefillGuestTestIDs.refillGuestFindStoreNext}
            logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
          >
            {getText("review")}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

interface OtherInformationNavigationProps
  extends NativeStackScreenProps<
    RefillStackParamList,
    "refill-other-information"
  > {}
