import {TranslationMapping} from "../localization";

const mapping: TranslationMapping = {
  "signup": "Inscribirse",
  "login": "Iniciar sesión",
  "username": "Nombre de usuario",
  "password": "Clave",
  "dashboard": "Tablero",
  "medications": "Medicamentos",
  "messages": "Mensajes",
  "profile": "Perfil",
  "get-care": "Obtenga atención",
  "lets-get-to-know-you": "vamos a conocerte",
  "lets-get-to-know-you-details": "Complete su formulario de nuevo paciente para que tengamos toda su información archivada. Esto asegura que podamos surtir su receta correctamente.",
  "welcome": "Bienvenidos",
  "logout": "Cerrar sesión",
  "front": "Delantero",
  "back": "atrás",
  "back-to-login": "Atrás para iniciar sesión",
  "otc-medications": "Medicamentos de venta libre",
  "next": "Siguiente",
  "scan-barcode": "Escanear código de barras",
  "scanned": "Escaneado",
  "save": "Guardar",
  "forgot-password": "¿Olvidaste tu contraseña?",
  "camera-access-denied": "No se puede acceder a la cámara, vaya a la configuración de su aplicación y conceda acceso a la cámara.",
  "username-is-required": "Se requiere el nombre de usuario",
  "password-is-required": "Se requiere la contraseña",
  "view-full-profile": "Ver perfil completo",
  "insurance-card": "Tarjeta de seguro",
  "barcode-scanner": "Escaner de código de barras",
  "anonymous-refill": "Recarga anónima",
  "english": "Inglés",
  "spanish": "Español",
  "french": "Francés",
  "yes": "Sí",
  "no": "No",
  "continue": "Continuar",
  "first-name": "Nombre *",
  "first-name-is-required": "Se requiere el nombre",
  "last-name": "Apellido *",
  "last-name-is-required": "Se requiere el apellido",
  "email-short": "Correo electrónico",
  "email": "Correo electrónico",
  "email-is-required": "Se requiere el correo electrónico",
  "email-continue": "Continuar con el correo electrónico",
  "mobile-number": "Número de móvil *",
  "mobile-number-is-required": "Se requiere el número de móvil",
  "birth-date": "Fecha de nacimiento *",
  "birth-date-is-required": "Se requiere la fecha de nacimiento",
  "lets-get-started": "Comencemos por crear una cuenta.",
  "front-of-card": "Frente de la tarjeta",
  "back-of-card": "Fondo de la tarjeta",
  "cancel": "Cancelar",
  "cancellations": "Cancelaciones",
  "retake": "Volver a tomar",
  "use-photo": "Usar foto",
  "password-must-include": "La contraseña debe incluir",
  "at-least-8-characters": "al menos 8 caracteres",
  "at-least-1-number": "al menos 1 número",
  "at-least-1-capital-letter": "al menos 1 letra mayúscula",
  "at-least-1-lowercase-letter": "al menos 1 letra minúscula",
  "at-least-1-special-character": "al menos 1 carácter especial (! @ # $ % ^ & * etc.)",
  "privacy-policy-error": "Por favor lea la política de privacidad antes de aceptar",
  "privacy-policy-agree": "Estoy de acuerdo con los términos y condiciones anteriores",
  "privacy-policy-email-signup": "Regístrese para recibir correos electrónicos de mi farmacia",
  "pharmacy-privacy-policy": "Política de privacidad de la Farmacia",
  "terms-and-conditions": "Proveedor de Software Términos & Condiciones",
  "terms-of-service": "Términos de servicio",
  "terms-of-service-error": "Lea los términos del servicio antes de aceptar",
  "terms-of-service-agree": "Estoy de acuerdo con los términos y condiciones anteriores",
  "terms-of-service-email-signup": "Regístrese para recibir correos electrónicos",
  "and": "y",
  "submit": "Enviar",
  "2-of-2-agreements": "2 de 2 acuerdos",
  "medical-info-step-3": "Paso 3 de 8 / Agregue su información médica",
  "password-entry-does-not-meet-criteria": "La entrada de la contraseña no cumple los criterios",
  "password-entries-do-not-match": "Las entradas de contraseña no coinciden",
  "create-your-account": "Crea tu cuenta",
  "please-enter-your-new-password": "Por favor, introduzca su nueva contraseña",
  "email-is-not-valid": "El correo electrónico no es válido",
  "phone-is-not-valid": "El teléfono no es válido",
  "create-account": "Crear cuenta",
  "confirm-password": "Confirmar contraseña",
  "patient-info-step": "Paso x de y / Confirma tu información",
  "communications-language": "Lenguaje de comunicaciones",
  "prescriptions-communications": "Comunicaciones de recetas: ¿Cómo le gustaría que le notifiquen cuando sus recetas estén listas?",
  "automated-voice-call": "Llamada de voz automatizada",
  "text-message": "Mensaje de texto",
  "app-notifications": "Notificaciones de aplicación",
  "all-other-pharmacy-communications": "Todas las demás comunicaciones de la farmacia",
  "same-time-preferences": "¿Le gustaría que sus recetas estuvieran listas juntas a la misma hora cada mes?",
  "additional-information": "Información Adicional",
  "visually-impaired": "Discapacidad visual",
  "hearing-impaired": "Personas con discapacidad auditiva",
  "easy-open-bottle-caps": "Tapas de botellas fáciles de abrir",
  "personal-info-step-2": "Paso 2 de 7 / Agrega tu información personal",
  "country": "País",
  "country-is-required": "Se requiere el país",
  "state": "Estado",
  "state-is-required": "Se requiere el estado",
  "city": "Ciudad",
  "city-is-required": "Se requiere la ciudad",
  "street-address": "Dirección",
  "street-address-is-required": "Se requiere la dirección",
  "street-address-line-2": "Dirección 2",
  "zip-code": "Código postal",
  "zip-code-is-required": "Se requiere el código postal",
  "gender": "Género (como aparece en seguro)*",
  "gender-is-required": "Se requiere género",
  "male": "Masculino",
  "female": "Femenina",
  "verification-code-resent": "Código de verificación reenviado",
  "please-enter-code": "Ingrese el código que le enviamos por correo electrónico",
  "didnt-receive-email": "¿No recibiste el correo electrónico?",
  "please-check-spam-folder": "Por favor revise su carpeta de correo no deseado o reenvíe el código",
  "didnt-receive-text-message": "¿No recibiste el mensaje de texto?",
  "phone-number-is-sms-capable": "Asegúrese de que el número de teléfono que ingresó sea compatible con SMS o reenvíe el código.",
  "standard-text-messages-apply": "Es posible que se apliquen tarifas estándar de mensajes de texto según su proveedor de servicios.",
  "resend-code": "Reenviar codigo",
  "reset-password": "Restablecer contraseña",
  "reset-link-sent": "Si el correo electrónico está asociado con una cuenta activa, debería recibir un enlace de reinicio.",
  "please-enter-email": "Por favor, introduzca la dirección de correo electrónico asociada a su cuenta",
  "how-would-you-like-to-receive-reset-link": "¿Cómo le gustaría recibir el enlace de reinicio?",
  "using-the-phone-number-on-file": "Usando el número de teléfono en el archivo",
  "send-reset-link": "Enviar enlace de reinicio",
  "create-new-password": "Crear nueva contraseña",
  "confirm-new-password": "Crear contraseña *",
  "new-password": "Nueva contraseña *",
  "for-security-reasons": "Por razones de seguridad, su contraseña caduca cada 90 días, por favor actualice su contraseña",
  "update-password": "Actualizar contraseña",
  "password-expiring-soon": "La contraseña caduca pronto",
  "password-expire-in-7-days": "Su contraseña expirará en 7 días",
  "update-password-now": "Desea actualizar su contraseña ahora?",
  "confirm-update-password": "Confirmar nueva contraseña *",
  "go-to-login": "Ir al inicio de sesión",
  "return-to-login": "Volver al inicio de sesión",
  "apple-signup": "Regístrese en Apple",
  "apple-continue": "Continuar con Apple",
  "apple-login": "Inicio de sesión de Apple",
  "change-password": "Cambiar contraseña",
  "login-with-email": "Iniciar sesión con el correo electrónico",
  "login-enter-email": "Insertar email",
  "passwords-must-match": "Las contraseñas deben coincidir",
  "dismiss": "Descartar",
  "load-more": "Cargar más",
  "loading": "Cargando",
  "open-map": "Abrir mapa",
  "input-field": "Campo de entrada",
  "text-field": "Campo de texto",
  "show-hide-password": "Mostrar/ocultar contraseña",
  "search": "Buscar",
  "select": "Seleccionar",
  "use": "Usar",
  "no-options": "No hay opciones",
  "email-or-password-incorrect": "El correo electrónico o la contraseña son incorrectos. Vuelva a intentarlo.",
  "unable-to-complete-registration": "No se puede completar el registro. Inténtalo de nuevo.",
  "unable-to-update-account": "No se puede actualizar la cuenta. Inténtalo de nuevo.",
  "unable-to-complete-verification": "No se puede completar la verificación. Inténtalo de nuevo.",
  "unable-to-resend-verification-code": "No se puede reenviar el código de verificación. Inténtalo de nuevo.",
  "error-submitting": "Error al enviar. Inténtalo de nuevo.",
  "unable-to-send-reset-link": "No se puede enviar el enlace de reinicio. Inténtalo de nuevo.",
  "unable-to-reset-password": "No se puede restablecer la contraseña. Inténtalo de nuevo.",
  "store-selector-change": "Cambio",
  "store-selector-search-address": "Tienda, Ciudad o Código postal",
  "store-selector-change-store": "Cambio tienda",
  "store-selector-your-store": "Tu tienda",
  "store-selector-nearby-store": "Tiendas cercanas",
  "store-selector-close": "Cerrar",
  "store-selector-select": "Seleccione",
  "store-selector-no-stores-error": "No se puede encontrar la ubicación de la farmacia",
  "feedback": "Retroalimentación",
  "feedback-give-feedback": "Dar retroalimentación",
  "feedback-give-your-feedback": "Dé su retroalimentación",
  "feedback-please-enter-feedback": "Ingrese su retroalimentación",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Retroalimentación enviada!",
  "feedback-thank-you": "Gracias por su retroalimentación!",
  "feedback-what-type-of-feedback": "Qué tipo de comentarios estás dejando hoy?",
  "feedback-target-is-required": "El objetivo de comentarios es obligatorio.",
  "feedback-feedback": "Ingrese información adicional",
  "feedback-feedback-is-required": "Se requiere información de comentarios.",
  "feedback-allowContact": "Acepto que se ponga en contacto conmigo para obtener más aclaraciones.",
  "feedback-thanks-message": "Gracias por tus comentarios!",
  "feedback-reach-you-message": "Nos pondremos en contacto con usted directamente si tenemos preguntas.",
  "feedback-back-to-profile": "Volver al perfil",
  "feedback-error-occurred": "Se ha producido un error.",
  "feedback-contact-info": "Datos de contacto",
  "feedback-edit": "Editar",
  "feedback-email-address": "Dirección de correo electrónico",
  "feedback-phone-number": "Número de teléfono",
  "feedback-app": "App",
  "phone-number": "teléfono: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Sitio web de farmacia y aplicación móvil proporcionada por",
  "feedback-location": "Tienda",
  "refill-card-medication": "Medicamento",
  "refill-card-due-date": "Recarga vencida",
  "prescription-card-day-supply": "suministro diario",
  "prescription-card-filled-on": "lleno de",
  "prescription-card-refills": "Recargas restantes",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Llene su receta",
  "medications-ready-to-be-filled": "Listo para ser llenado",
  "medications-other-upcoming-refills": "Otras próximas recargas",
  "insurance-card-upload-error": "No se puede cargar la tarjeta de seguro. Inténtalo de nuevo.",
  "camera-mask-label": "Centre el frente de su tarjeta de seguro en su marco",
  "insurance-add-your-information": "Agrega tu información de seguro",
  "insurance-take-card-picture": "Tome una foto o cargue una imagen de ambos lados de su tarjeta de seguro",
  "take-photo": "Toma una foto",
  "choose-photo": "Elija una foto",
  "insurance-bring-to-store": "lo llevaré a la tienda",
  "google-signup": "Regístrese con Google",
  "google-continue": "Continuar con Google",
  "google-login": "Inicio de sesión de Google",
  "google-login-failed": "Error de inicio de sesión de Google",
  "patient-stores": "Encuentra tu tienda",
  "date-is-not-valid": "La fecha no es válida",
  "verify-your-email": "Verifique su dirección de correo electrónico",
  "verify-your-phone": "verifica tu numero de telefono",
  "please-enter-your-information": "Por favor ingrese su información",
  "email-address": "Dirección de correo electrónico",
  "email-is-invalid": "Correo electronico es invalido",
  "passwords-do-not-match": "Las entradas de contraseña no coinciden",
  "password-does-not-meet-criteria": "La entrada de contraseña no cumple con los criterios",
  "follow-reset-password-instructions": "Siga las instrucciones para restablecer su contraseña.",
  "pharmacy-reset-link-sent": "Se ha enviado un correo electrónico a ",
  "need-help": "Necesitas ayuda? (877) 959-7550",
  "forms": "Formularios",
  "tasks": "Tareas",
  "patients": "Pacientes",
  "refill-submissions": "Envíos de recarga",
  "refill-submissions-header": "Envíos de recarga",
  "upcoming-refill": "Próxima recarga",
  "user": "Usuario",
  "users": "Usuarios",
  "new-user": "Nuevo usuario",
  "user-details": "Detalles de usuario",
  "title": "Título",
  "role": "Role",
  "closed": "Cerrada",
  "send-message": "Enviar mensaje",
  "revoke": "Revocar",
  "last-active": "Último activo",
  "first-name-is-not-valid": "El nombre no es válido",
  "last-name-is-not-valid": "El apellido no es válido",
  "registered": "Registrado",
  "meds": "Medicamentos",
  "orders": "Pedidos",
  "reminders": "Recordatorios",
  "activity": "Actividad",
  "show": "Mostrar",
  "login-attempts-exceeded": "Se superaron los intentos de inicio de sesión",
  "max-attempts-exceeded": "Máximo de intentos excedidos",
  "account-locked-reason": "Su cuenta ha sido bloqueada porque alcanzó la cantidad máxima de intentos de inicio de sesión no válidos permitidos.",
  "security-reason": "Por motivos de seguridad, elija una de las siguientes opciones para cambiar su contraseña y recuperar su cuenta.",
  "send-me-email": "Envíame un correo electrónico",
  "send-me-sms": "Envíame un mensaje de texto",
  "view-activity-logs": "Ver registros de actividad",
  "resend-invite": "Reenviar invitación",
  "delete": "Borrar",
  "delete-confirmation": "¿Estás seguro de que quieres eliminar esto?",
  "cannot-undo-action": "No puede deshacer esta acción.",
  "revoke-access-for-authorized-caregiver": "Revocar el acceso significa que {{firstName}} {{lastName}} ya no es uno de sus cuidadores autorizados.",
  "set-your-preferences": "Paso {{currentStep}} de {{totalSteps}} / Establece tus preferencias",
  "find-your-store": "Encuentra tu tienda",
  "confirm-your-store": "Paso {{currentStep}} de {{totalSteps}} / Confirma tu tienda",
  "order-submitted": "¡Su pedido ha sido enviado!",
  "relation-to-primary-user": "Relación con el usuario principal",
  "order": "Orden",
  "picking-up-at": "Recogida en",
  "ready-today": "Listo hoy entre",
  "prescribed-for": "Prescrito para",
  "rx-number": "Número de prescripción",
  "last-filled-on": "Llenado por última vez el",
  "last-ordered-on": "Pedido por última vez el",
  "auto-refills": "Auto-recargas",
  "auto-refill": "Auto-recargas",
  "refills-left": "Recargas restantes",
  "quantity-dispensed": "Cantidad dispensada",
  "days-supply": "Suministro de días",
  "directions": "Direcciones",
  "questions-about-medication": "Preguntas sobre este medicamento?",
  "ask-your-pharmacist": "Pregunta a tu farmacéutico",
  "message": "Mensaje",
  "refill": "Rellenar",
  "child": "Niño",
  "spouse": "Esposo",
  "parent": "Padre",
  "grandparent": "Abuelo",
  "grandchild": "Nieto",
  "pet": "Mascota",
  "partner": "Pareja",
  "other": "Otro persona bajo mi cuidado",
  "resources": "RECURSOS",
  "medication-guides": "Guías de Medicamentos",
  "how-to-take": "Cómo tomar este medicamento",
  "patient-education-sheets": "Hojas de Educación para el Paciente",
  "side-effects-interactions": "Efectos secundarios, interacciones medicamentosas",
  "prescription-written": "Receta escrita",
  "prescription-by": "Prescripción por",
  "call-prescriber": "Prescriptor: ",
  "done": "Hecho",
  "do-this-later": "Haz esto más tarde",
  "entered-prescription-manually": "Ingresado manualmente",
  "your-prescriptions": "Sus recetas",
  "add": "Agregar",
  "drug-name": "Nombre de la medicamento",
  "drug-name-is-required": "Se requiere el nombre del medicamento",
  "add-your-prescriptions": "Agrega tus recetas",
  "step": "Paso",
  "rx-number-is-required": "Se requiere el número de Rx",
  "change": "Cambia",
  "location": "Ubicación",
  "locations": "Ubicaciones",
  "patient-dob": "Fecha de nacimiento del paciente (mm/dd/aaaa) *",
  "patient-dob-is-required": "Se requiere la fecha de nacimiento",
  "patient-last-name-is-required": "Se requiere el apellido del paciente",
  "patient-last-name": "Apellido del paciente *",
  "we-found-your-medications-alert-title": "Encontramos sus medicamentos",
  "unable-to-locate-your-prescription-alert-title": "No se pudo ubicar su lista de recetas",
  "unable-to-locate-your-prescription-alert-description": "Confirme que la siguiente información coincida con la etiqueta de su receta",
  "prescription-information": "Información de prescripción",
  "integrated-prescription-flow-description": "Ingrese cualquier número de receta reciente para conectar su lista de recetas con la tienda específica que seleccionó",
  "hi": "Hola, {{firstName}}.",
  "create-your-password": "Por favor crea tu contraseña",
  "i-have-read-and-agree": "He leído y estoy de acuerdo con",
  "pharmacy-terms-and-conditions": "Términos y Condiciones",
  "privacy-policy": "Política de Privacidad",
  "terms-and-conditions-required": "Se requiere un acuerdo de términos y condiciones",
  "privacy-policy-required": "Se requiere acuerdo de política de privacidad",
  "new-patient": "Nuevo paciente",
  "i-am-a-new-patient": "Soy un nuevo paciente",
  "patient-details": "Detalles del paciente",
  "edit": "Editar",
  "date-of-birth": "Fecha de nacimiento",
  "date-of-birth-short": "FDN",
  "phone-number-footer": "Número de teléfono",
  "gender-text": "Género",
  "language": "Idioma",
  "conditions": "Condiciones",
  "easy-open-bottle": "Botella abrefácil",
  "med-sync": "Sincronización Médica",
  "photo-id": "Identificación con foto",
  "image": "Imagen",
  "notification": "Notificación",
  "app-notification": "Notificación de la aplicación",
  "list": "Lista",
  "providers": "Proveedores",
  "notes": "Notas",
  "internal-use-only": "Solo uso interno",
  "continuation-disclaimer": "Al tocar Continuar con Apple, Google o el correo electrónico, acepta las condiciones de Lumistry",
  "to-get-started-choose": "Para comenzar,\nelija uno de los siguientes:",
  "face-id": "Identificación facial",
  "set-my-store": "Establecer como mi tienda",
  "medical-equipment-immunization": "Inmunizaciones, Equipo médico duradero, Conteo, Consulta de pacientes, Vacuna contra el COVID-19",
  "services-offered": "Servicios ofrecidos",
  "en-es-fr": "Inglés, Español, Francés",
  "languages-spoken": "Idiomas que se hablan en este lugar",
  "holiday-hours-vary": "El horario de vacaciones puede variar.",
  "open-hours": "Abierto hoy de: {{hours}}",
  "open-today": "Abierta hoy las 24 horas.",
  "store-hours": "Horario de la tienda",
  "upload-csv": "Cargar csv",
  "personal-info": "Información personal",
  "link-expired": "El enlace ha caducado o es inválido, necesitará uno nuevo.",
  "reset-unsuccessful": "Restablecimiento fallido",
  "will-pickup-prescription-today": "¿Recogerá su receta hoy?",
  "field-required": "Este campo es obligatorio",
  "will-pickup-prescription-urgently": "¿Necesita recoger su receta con urgencia?",
  "order-details": "Detalles del pedido",
  "method": "Método",
  "note-for-pharmacy": "Nota para la farmacia (opcional)",
  "note-for-pharmacy-not-optional": "Nota para la farmacia",
  "review": "Revisar",
  "prescription-find-server-error": "Error interno del servidor durante la búsqueda de sus medicamentos",
  "no-allergies": "Sin alergias conocidas",
  "insurance-is-required": "Se requiere seguro",
  "insurance-change-last-refill": "¿Ha cambiado su seguro desde su última recarga? *",
  "prescription-ready-each-month": "¿Le gustaría que todos sus medicamentos recetados estuvieran listos al mismo tiempo cada mes?",
  "prescriptions-is-required": "Se requieren recetas",
  "over-the-counter-medications": "Medicamentos de venta libre",
  "enter-patient-information": "Ingrese la información del paciente",
  "please-review-messages-and-proceed": "Revise los mensajes a continuación y háganos saber cómo le gustaría proceder.",
  "how-to-proceed": "Cómo proceder",
  "enter-patient-email": "Ingrese la dirección de correo electrónico del paciente",
  "add-patient-request-submit": "Agregar solicitud de paciente enviada",
  "provide-an-email": "Veuillez fournir l'adresse e-mail que {{firstName}} {{lastName}} utilise pour le compte de {{firstName}} avec {{pharmacyName}}",
  "request-sent": "Solicitud enviada a {{firstName}} {{lastName}}",
  "approval-needed": "Se necesita la aprobación de esta persona dentro de los 14 días.",
  "person-has-an-account": "Si esta persona tiene una cuenta en {{pharmacyName}}, recibirá su solicitud",
  "add-another-person": "Agregar otra persona",
  "add-another": "Añadir otro",
  "go-home": "Vete a casa",
  "allow-pharmacy-contact-prescriber": "Permitir que la farmacia se comunique con el prescriptor",
  "continue-with-selected-items": "Continuar con los elementos seleccionados",
  "go-back": "Volver",
  "go-back-update-order": "Volver a actualizar mi pedido",
  "thank-you-for-choosing": "Gracias por elegir",
  "return-to-order-details": "Volver a los detalles del pedido",
  "add-people-under-care": "Agregar personas bajo su cuidado",
  "add-person": "Agregar una persona",
  "add-people-under-care-message-pt-1": "Vous pouvez trouver la personne que vous souhaitez ajouter en fournissant ses informations sur la page suivante",
  "add-people-under-care-message-pt-2": "Si la persona que está agregando tiene más de 18 años, también deberá proporcionar la dirección de correo electrónico que usa para su cuenta en",
  "unable-to-process-items": "No se pueden procesar uno o más elementos",
  "we-will-contact-prescriber": "Nos comunicaremos con su prescriptor para obtener la autorización de las siguientes recetas.",
  "please-review-the-messages-below": "Revise los mensajes a continuación y háganos saber cómo le gustaría proceder.",
  "ready-to-be-filled": "Listo para ser llenado",
  "other-details": "Otros detalles",
  "store-info": "Información de la tienda",
  "account-setup": "Configuración de cuenta",
  "other-information": "Otra información",
  "notice": "Aviso",
  "insurance": "Seguro",
  "add-patient": "Agregar paciente",
  "manage-prescriptions": "Gestiona tus recetas digitalmente",
  "pick-up-prescriptions": "Recoge tus recetas",
  "discuss-care": "Hable sobre su atención con un miembro del equipo de {{pharmacyName}}",
  "manage-health": "Administre sus citas de servicios clínicos y de salud",
  "request-status": "Pendiente: enviado el {{submittedDate}}",
  "puc-permission-access-to-records": "Confirmo que {{firstName}} {{lastName}} tiene permiso para acceder a mis registros y administrar la salud digitalmente y que puedo revocar el acceso en cualquier momento",
  "if-you-authorize-person": "Si autoriza esta solicitud, entonces esta persona puede:",
  "access-validity": "Este acceso es válido hasta que lo revoques.",
  "legal-guardian-confirmation-for-person": "Usted acepta que es padre o tutor legal de la persona que está agregando",
  "legal-guardian-agreement": "Sí, soy padre o tutor legal de {{firstName}} {{lastName}}.",
  "confirmation": "Confirmación",
  "add-minor-or-pet-patient": "Agregar paciente menor o mascota",
  "your-allergies": "Tus alergias",
  "revoke-access": "Revocar acceso",
  "authorized-on": "Autorizado el {{authorizedDate}}",
  "revoke-access-in-settings": "Puede revocar el acceso yendo a su Información médica en Configuración de la cuenta",
  "review-request": "Solicitud de revisión...",
  "authorize": "Autorizar",
  "decline": "Rechazar",
  "authorized-caregivers": "Cuidadores autorizados",
  "update-weak-password": "Su contraseña no cumple con los requisitos actuales. Para la seguridad y protección de su cuenta, cree una nueva.",
  "close": "Cerrar",
  "unable-to-submit-people-under-care-request": "No se pueden enviar personas bajo solicitud de atención",
  "your-password-has-been-successfully-changed": "Su contraseña ha sido cambiada exitosamente",
  "people-under-your-care": "Personas bajo su cuidado",
  "appointments": "Equipo",
  "add-medications": "Agregar medicamentos",
  "add-meds-text": "Puede pedir resurtidos de recetas fácilmente y administrar medicamentos agregándolos ahora, u omita este paso para hacerlo más tarde",
  "prescriptions-at-file": "Las recetas están archivadas en Cameron's Pharmacy",
  "request-to-transfer": "Solicitud de transferencia de recetas de una farmacia diferente",
  "skip-this-step": "Saltar este paso",
  "do-this-medications-tab": "Puede hacerlo en la pestaña Medicamentos",
  "account": "Cuenta",
  "greeting": "Buen día",
  "people-under-your-care-empty-page": "Si está administrando recetas y citas para otras personas o mascotas, tenerlas todas en una sola lista es una forma conveniente de realizar un seguimiento de los medicamentos y los horarios de todos, lo que puede ayudar a evitar renovaciones y citas perdidas",
  "add-person-or-pet": "Agregar persona o mascota",
  "relationship-to-me": "Relación conmigo",
  "medical-info": "Información médica",
  "none": "Ninguno",
  "refill-details": "Detalles de recarga",
  "success": "Éxito",
  "prescription-no": "Prescripción No.",
  "name": "Nombre",
  "status": "Estado",
  "submitted": "Presentado",
  "channel": "Canal",
  "delivery-method": "Método de entrega",
  "refill-comments": "Comentarios de recarga",
  "patient-info": "Información del paciente",
  "details": "Detalles",
  "phone": "Teléfono",
  "address": "Dirección",
  "email-text": "Correo electrónico",
  "prescription": "Prescripción",
  "patient": "Paciente",
  "monday": "Lunes",
  "tuesday": "Martes",
  "wednesday": "Miércoles",
  "thursday": "Jueves",
  "friday": "Viernes",
  "saturday": "Sábado",
  "sunday": "Domingo",
  "date-submitted": "Fecha Enviado",
  "allergies": "Alergias",
  "medical-conditions": "Condiciones medicas: ",
  "refill-past-due": "Recarga vencida:",
  "caregiver-approval-agreement": "Acuerdo de aprobación del cuidador",
  "no-known-allergies": "Sin alergias conocidas",
  "more-information": "Más información",
  "monthly-prescriptions": "¿Le gustaría que sus recetas estuvieran listas a la misma hora todos los meses?",
  "as-an-authorized-caregiver": "Como cuidador autorizado, usted puede:",
  "manage-prescriptions-digitally": "Administre las recetas de {{firstName}} digitalmente",
  "order-refills": "Pedir recargas para {{firstName}}",
  "delivery": "Entrega",
  "mail": "Correo",
  "curbside": "Acera",
  "would-like-easy-open-caps": "¿Le gustaría tapas de botellas de fácil apertura?",
  "prefers-easy-open-caps": "Sí, prefiero las tapas de fácil apertura",
  "prefers-safety-caps": "No, prefiero tapas de seguridad",
  "pick-up-prescriptions-for": "Recoger recetas para {{firstName}}",
  "discuss-care-with": "Hable sobre la atención de {{firstName}} con un miembro del equipo de {{pharmacyName}}",
  "manage-health-and-clinic": "Discutez des soins de {{firstName}} avec un membre de l'équipe de {{pharmacyName}}",
  "access-validity-puc": "Este acceso es válido hasta que Chris cumpla 18 años o hasta que elimine a Chris de su lista de personas bajo su cuidado.",
  "update-puc-list": "Puede actualizar su lista yendo a Personas bajo mi cuidado en Configuración de la cuenta",
  "patient-record-created": "Registro de paciente creado para {{firstName}} {{lastName}}",
  "refill-due": "Refill debido:",
  "store": "Comercio",
  "app": "App",
  "medications-empty-state-title": "Medicamentos",
  "medications-empty-state-description": "¡Agregar sus medicamentos facilitará el seguimiento y la solicitud de renovaciones de recetas importantes que lo ayudarán a mantenerse saludable!",
  "medications-reminders-empty-state-title": "Recordatorios de medicamentos",
  "medications-reminders-empty-state-description": "Los recordatorios de medicamentos son una buena manera de mantenerse al tanto de sus medicamentos que pueden ayudar a evitar riesgos innecesarios y enfermedades graves",
  "add-meds": "Agregar medicamentos",
  "transfer-meds": "Transferir medicamentos de otra farmacia",
  "pickup": "Levantar",
  "select-an-option": "Seleccione una opción",
  "choose-from-the-list": "Por favor, elija de la lista de artículos antes de hacer clic en el botón de pedido",
  "select-one-item": "Selecciona al menos un elemento",
  "show-more": "Mostrar más",
  "street1": "Calle 1",
  "street2": "Calle 2",
  "gender-on-insurance": "Género (como aparece en su seguro)",
  "preferred-language": "Idioma preferido",
  "notification-preferences": "Preferencias de notificación",
  "auto-voice-call": "Llamada de voz automática",
  "edit-patient": "Editar paciente",
  "show-less": "Muestra meno",
  "please-confirm-the-info-matches-prescription-label": "Confirme que la información a continuación coincide con la etiqueta de su receta",
  "register-confirmation-heading": "Configuración de nueva cuenta",
  "register-confirmation-body": "Se ha enviado un nuevo enlace de configuración de cuenta a su correo electrónico. Por favor revise su correo electrónico y haga clic en el enlace proporcionado.",
  "orders-tab-empty-state-title": "Historial de pedidos",
  "orders-tab-empty-state-description": "¡Realizar un pedido de recarga es fácil desde la pestaña Meds! Después de haber realizado un pedido, los detalles y el seguimiento aparecerán aquí",
  "start-an-order": "Iniciar un pedido",
  "show-locations": "Mostrar ubicaciones",
  "first-name-edit-user": "Nombre",
  "last-name-edit-user": "Apellido",
  "information": "Información",
  "intro": "Introducción",
  "enter-email": "Ingresar correo electrónico",
  "create-password": "Crear contraseña",
  "register-confirmation": "Confirmación de registro",
  "register": "Registrarse",
  "reset-link": "Restablecer enlace",
  "reset-successful": "Restablecimiento exitoso",
  "register-details": "Registrar detalles",
  "phone-verification": "Verificación del teléfono",
  "patient-pharmacy-stores": "Tiendas de farmacia para pacientes",
  "add-prescription": "Agregar receta",
  "refill-guest": "Recargar invitado",
  "home": "Hogar",
  "settings": "Configuraciones",
  "store-location": "Ubicación de la tienda",
  "expiring-soon": "Expirando pronto",
  "schedule": "Horario",
  "find-time": "Encontrar una hora",
  "learn-more": "Aprender más",
  "next-available": "Siguiente disponible",
  "not-available": "Actualmente no disponible",
  "minutes": "Minutos",
  "book-appointment": "Reservar Cita",
  "upcoming": "Próximo",
  "past": "Pasado",
  "patient-insurance": "Seguro del paciente",
  "barcode": "Código de barras",
  "patient-preferences": "Preferencias del paciente",
  "feedback-success": "Éxito de la retroalimentación",
  "people-under-care": "Personas bajo cuidado",
  "add-patient-under-care": "Agregar paciente bajo cuidado",
  "add-patient-under-care-info": "Agregar información de paciente bajo cuidado",
  "add-patient-under-care-email": "Agregar correo electrónico de paciente bajo cuidado",
  "add-patient-under-care-confirmation": "Agregar paciente bajo confirmación de atención",
  "patient-under-care-list": "Lista de pacientes bajo cuidado",
  "automatic-prescription-flow": "Flujo de prescripción automática",
  "success-automatic-prescription-flow": "Flujo de prescripción automática de éxito",
  "no-refills": "Sin recargas",
  "expired": "Caducado",
  "too-soon": "Demasiado pronto",
  "not-found": "No encontrado",
  "controlled": "Controlado",
  "narcotic": "Narcótico",
  "transferred": "Transferido",
  "discontinued": "descontinuado",
  "on-hold": "En espera",
  "deleted": "Eliminado",
  "fillable": "Rellenable",
  "partially-filled": "Parcialmente lleno",
  "out-of-stock": "Agotado",
  "special-order": "Pedido especial",
  "deceased": "Fallecido",
  "rejected-reason-unknown": "Razón del rechazo desconocida",
  "select-person": "Seleccione el paciente para esta cita. Si no los ve en la lista, deberá agregarlos primero.",
  "myself": "Mí mismo",
  "confirm": "Confirmar",
  "error-loading-appointments": "Error al cargar citas",
  "appointment-scheduled": "la cita está programada",
  "booking-error": "Algo salió mal al intentar reservar la cita. Inténtalo de nuevo.",
  "error-loading-slots": "Error al cargar los espacios para citas",
  "error-loading-patient-record": "Error al cargar el registro del paciente",
  "error-booking-appointment": "Error al reservar la cita",
  "when": "Cuando",
  "who": "Quien",
  "where": "Donde",
  "care-notes": "Notas de cuidado",
  "reschedule": "Reprogramar",
  "cancel-appointment": "Cancelar cita",
  "error-loading-appointment": "Error al cargar la cita",
  "error-loading-appointment-type": "Error al cargar el tipo de cita",
  "refill-medications": "refill-medications",
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "good-morning": "Buen día",
  "good-afternoon": "Buenas tardes",
  "good-evening": "Buena noches",
  "you-are-chatting-with": "Estás chateando con:",
  "new-message": "Nuevo mensaje",
  "no-messages": "Ningún mensaje",
  "messages-have-a-question": "Tengo una pregunta? Necesitas cambiar un pedido? Envíanos un mensaje seguro.",
  "new-message-placeholder": "Mensaje...",
  "general": "General",
  "billing": "Facturación",
  "med-info": "Con información",
  "side-effects": "Efectos secundarios",
  "message-subject-label": "Seleccione el tema que mejor se adapte a su mensaje:",
  "send": "Enviar",
  "appointment-rescheduled": "se ha reprogramado la cita",
  "error-rescheduling-appointment": "Error al reprogramar la cita",
  "cancel-appointment-confirmation": "¿Está seguro de que desea cancelar esta cita?",
  "appointment-canceled": "cita cancelada",
  "previously-scheduled": "Programado previamente:",
  "cancel-note": "Nota: Cancelar esta cita no la eliminará de los calendarios de terceros a los que se haya agregado.",
  "cancel-error": "Algo salió mal al intentar cancelar la cita. Vuelve a intentarlo.",
  "error-cancel-appointment": "Error al cancelar la cita",
  "keep": "Mantener",
  "of": "de",
  "prescription-card-last-filled": "Ultima recarga",
  "prescription-card-last-ordered": "Último pedido:",
  "prescription-card-refill-due": "Recarga vencida",
  "remove": "Elminar",
  "user-not-found": "User no encontrado",
  "invalid-confirmation-code": "Código de confirmación inválido",
  "contact-support": "Soporte de contacto",
  "many-failed-attempts": "Muchos intentos fallidos",
  "not-authorized": "No autorizado",
  "retrieve-your-prescriptions": "Recuperar sus recetas",
  "other-meds": "Otros medicamentos",
  "try-again": "Intentar otra vez",
  "notify-you": "Notificarte",
  "reschedule-appointment": "Re-agenda de citas",
  "tomorrow": "Mañana",
  "today": "Hoy",
  "canceled": "Cancelado",
  "events": "eventos",
  "error-field-number": "El campo debe ser un número",
  "no-appointments": "Actualmente no tiene citas.\nSeleccione un servicio para comenzar.",
  "no-services": "No hay servicios disponibles para reservar.",
  "video-header": "Vídeos",
  "videos": "Vídeos",
  "videos-filter": "FILTRAR",
  "videos-clear": "Claro",
  "videos-all": "Todo",
  "videos-medication": "Medicamento",
  "videos-condition": "Condición",
  "videos-administration": "Administración",
  "videos-general-health": "Salud general",
  "videos-missing-medication-video": "Video de medicamento faltante",
  "videos-search": "Buscar",
  "videos-share": "Compartir",
  "videos-category": "Categoría",
  "videos-title": "Título",
  "videos-language-spanish": "Ver en Español",
  "videos-language-english": "Ver en Inglés",
  "videos-language-french": "Ver en Francés",
  "videos-duration": "Duración",
  "videos-url": "URL",
  "copied-to-clipboard": "Copiado al portapapeles",
  "videos-med-guides": "Guías médicas",
  "videos-transcript": "Transcripción",
  "delete-confirmation-modal": "¿Está seguro de que desea eliminar el {{itemType}}: {{itemName}}?",
  "service": "Servicio",
  "create-appointment": "Crear una cita",
  "limit-booking-duration": "Limitar la duración total de la reserva",
  "limit-future-bookings": "Limitar futuras reservas",
  "limit-booking-frequency": "Limitar frecuencia de reserva",
  "before-event": "Antes del evento",
  "after-event": "Después del evento",
  "into-future": "hacia el futuro",
  "within-date-range": "Dentro de un rango de fechas",
  "add-limit": "Añadir límite",
  "calendar-days": "días calendario",
  "business-days": "días laborables",
  "per-day": "Por día",
  "per-week": "Por semana",
  "per-month": "Por mes",
  "no-buffer-time": "Sin tiempo de búfer",
  "content-box-title": "Visualización de tareas que coinciden con los siguientes criterios:",
  "duration": "Duración",
  "time": "Tiempo",
  "check-later": "Por favor, vuelva más tarde",
  "birthdate": "Fecha de nacimiento",
  "actions": "Comportamiento",
  "service-record-enabled-tooltip": "Marcar el servicio como no disponible para programar citas",
  "service-record-disabled-tooltip": "Habilitar hacer citas para este servicio",
  "copy-link-button-tooltip": "Copiar al portapapeles",
  "edit-service-record-tooltip": "Editar servicio",
  "delete-service-record-tooltip": "Eliminar servicio",
  "selecting-all-pages": "Selección de todas las páginas ({{count}} elementos)",
  "select-all-pages": "Seleccionar todas las páginas",
  "selecting-current-page": "Seleccionar solo esta página ({{count}} elementos)",
  "select-current-page": "Seleccionar solo esta página ",
  "unselect-all": "Deselecciona todo",
  "others": "otros",
  "reorder": "Reordenar",
  "order-refill": "Recarga de pedido",
  "generic-bulk-confirmation-modal": "¿Está seguro de que desea {{bulkAction }} {{count}} {{type}} {{changeType}}?",
  "task": "tarea",
  "bulk-assignee-confirmation-modal": "Seleccione el asignado para {{count}} {type}}.",
  "bulk-due-date-confirmation-modal": "Seleccione la fecha de vencimiento para {{count}} {type}}",
  "unresolved": "Sin resolver",
  "resolved": "Resuelto",
  "in_progress": "En Progreso",
  "on_hold": "En espera",
  "task-resolve": "Tareas Resolver",
  "mark-resolved": "Marcar como resuelto",
  "mark-in-progress": "Marcar como en progreso",
  "mark-flagged": "Marcar como marcado/sin marcar",
  "mark-unresolved": "Marcar como no iniciado",
  "mark-on-hold": "Marcar como en espera",
  "change-assignee": "Cambiar Usuario Asignado",
  "change-due": "Cambiar fecha de vencimiento",
  "task-in-progress": "Tareas en Progreso",
  "task-flag": "Indicador de tareas",
  "assignee": "cesionario",
  "not-started": "No iniciado",
  "delete-task": "Eliminar tarea",
  "venue": "Evento",
  "over-the-counter": "De venta libre",
  "private": "Privado",
  "public": "Público",
  "your-pharmacy": "Tu farmacia",
  "edit-availability-record-tooltip": "Editar disponibilidad",
  "delete-availability-record-tooltip": "Eliminar disponibilidad",
  "availability": "Disponibilidad",
  "new-chat-message": "Nuevo Mensaje de Chat",
  "your-refill-is-ready": "Tu recarga está lista",
  "hi-your-refill-is-ready": "Hola{{firstName}}, su recarga está lista para ser recogida.",
  "patient-is-required": "¡Se requiere paciente!",
  "subject-is-required": "¡El asunto es obligatorio!",
  "message-is-required": "¡El mensaje es obligatorio!",
  "message-characters-limit-five-thousand": "¡El límite de caracteres del mensaje es 5000!",
  "find-template": "Buscar plantilla",
  "to": "A",
  "subject": "Sujeto",
  "note-inbox-messages-should-be-clinical-in-nature": "Nota: Los mensajes de la bandeja de entrada deben ser de naturaleza clínica. El envío de mensajes de marketing a través de esta plataforma podría violar la Ley de Protección al Consumidor Telefónico y las regulaciones de la Comisión Federal de Comunicaciones. No solicite información de tarjetas de crédito.",
  "all": "Todo",
  "unread": "No leído",
  "unread-by-user": "No leído por el usuario",
  "includes-me": "Me incluye",
  "created-by-me": "Creado por mí",
  "last-seven-days": "Los últimos 7 días",
  "filters": "Filtros",
  "clear": "Despejar",
  "text-formatting": "Formato de texto",
  "send-attachment": "Enviar archivo adjunto",
  "type-your-message": "Escribe tu mensaje...",
  "mask-as-unread": "Enmascarar como no leído",
  "chat-messages": "Mensajes de chat",
  "search-chats": "Buscar chats",
  "find-patient": "Encontrar paciente",
  "new-subject": "Nuevo tema",
  "add-mobile-number-send-message": "Añadir un número de móvil para enviar un mensaje",
  "add-mobile-number": "Añadir número de móvil",
  "newest": "El mas nuevo",
  "oldest": "Más antiguo",
  "a-z": "A-Z",
  "new-chat": "Nueva conversación",
  "inbox": "Bandeja de entrada",
  "auto": "Auto",
  "previous-week": "Semana anterior",
  "current-week": "Semana actual",
  "next-week": "Próxima semana",
  "secure-message": "mensaje seguro",
  "unable-to-process": "No se pudo procesar su solicitud",
  "try-again-later": "Inténtelo de nuevo más tarde. Si necesita asistencia inmediata, comuníquese con su farmacia.",
  "message-pharmacy": "Mensaje farmacia",
  "call-pharmacy": "Llamar a la farmacia",
  "tasks-grid-no-results-title": "Tu criterio de búsqueda no tiene ninguna tarea en el marco de tiempo actual",
  "tasks-grid-no-results-subtitle": "No pudimos encontrar ninguna tarea en el marco de tiempo actual según su vista y filtros. Use los botones Anterior / Siguiente para navegar en el tiempo, o el botón Semana actual para restablecer la vista a los valores predeterminados.",
  "get-directions": "Obtener las direcciones",
  "explore-other-locations": "Explore otras ubicaciones que ofrecen este servicio",
  "error-getting-locations": "Error al obtener ubicaciones",
  "zip-code-is-not-valid": "El código postal no es válido",
  "street-1-is-not-valid": "La calle 1 no es válida",
  "street-2-is-not-valid": "La calle 2 no es válida",
  "allergies-are-not-valid": "Las alergias no son válidas, un ejemplo correcto es all1,all2,all3...",
  "conditions-are-not-valid": "Las condiciones no son válidas, un ejemplo correcto es cond1,cond2,cond3...",
  "make-appointment": "Hacer una cita",
  "find-service-at-location": "Encontrar servicios en una ubicación diferente",
  "get-more-stores": "Obtener más tiendas",
  "error-loading-patients-under-care": "Error al cargar pacientes bajo cuidado",
  "add-new-patient": "Agregar nuevo paciente...",
  "after-adding-patient": "Después de agregar un nuevo paciente, regrese a Citas y seleccione el servicio deseado.",
  "pending-requests": "Solicitudes pendientes:",
  "add-calendar-event": "Agregar al calendario",
  "by-application": "Por aplicación",
  "by-email": "Por correo electrónic",
  "by-sms": "Por SMS",
  "by-voice": "Por voz",
  "error-loading-patient": "Ocurrió un error al tratar de obtener al paciente. Vuelva a intentarlo.",
  "in": "en",
  "ago": "hace",
  "month-short": "mes",
  "hour-short": "hora",
  "day-short": "día",
  "missing-important-patient-record-data": "Al registro del paciente le faltan datos de importación.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Restablecer filtros",
  "calendar": "Calendario",
  "no-medication-guide": "Actualmente no disponible. Si tiene preguntas sobre este medicamento, comuníquese con su farmacia.",
  "appointment-panel": "Panel de citas",
  "age": "Edad",
  "confirmed": "Confirmado",
  "pending": "Pendiente",
  "rejected": "Rechazado",
  "service-description": "Descripción del servicio",
  "additional-info": "Información adicional",
  "more": "Mas",
  "less": "Menos",
  "month": "Mes",
  "week": "Semana",
  "day": "Día",
  "dont-see-service": "¿No ves el servicio que necesitas?",
  "service-category": "Categoría de servicio",
  "team": 'Equipo',
  "personal": "Personal",
  "reschedule-note": "Nota: Reprogramar esta cita no la eliminará de los calendarios de terceros a los que se haya agregado.",
  "ordered":"Ordenada",
  "characters-count": "Los caracteres cuentan: {{count}}",
  "maxim-count-attachments": "El recuento máximo de archivos adjuntos es {{count}}.",
  "selecting-files-wrong": "Algo salió mal al seleccionar archivos.",
  "files-sending-error-try-again": "Algo salió mal al enviar archivos. Inténtalo de nuevo.",
  "home-account-subheading": "Ajustes, seguros, ayuda, etc.",
  "home-medications-subheading": "Administre medicamentos y ordene recargas",
  "home-messages-subheading": "Chatee de forma segura con la farmacia",
  "home-appointments-subheading": "Ver o programar servicios clínicos",
  "home-puc-subheading": "Agregar una persona...",
  "create-form-first": "Por favor crea un formulario primero",
  "no-allergies-2": "Sin alergias",
  "forms-fill-out":"Complete {{formsCount}} formularios requeridos...",
  "health-info":"Revisar información de salud relacionada",
  "insurance-card-reminder":"Traer tarjeta de seguro",
  "pre-appointment-checklist":"Lista de verificación previa a la cita",
  "error-updating-appointment":"Error al reprogramar la cita",
  "no-forms-required": "No se requirieron formularios para esta cita",
  "form-unavailable": "El formulario ya no está disponible",
  "form-not-started": "Formulario no iniciado",
  "form-submitted": "El formulario ha sido enviado",
  "preparing-submission":"Preparando el envío",
  "download-will-begin":"Su descarga comenzará en breve.",
  "delete-submission-confirmation":"¿Está seguro de que desea eliminar este envío?",
  "not-entered":"No ingresado",
  "submitted-on":"Enviado el",
  "download-pdf":"Descargar PDF",
  "submission-modal":"Modal de envío",
  "checklist-complete" : "Lista de verificación completada",
  "checklist-incomplete": "Por favor complete la lista de verificación"
};

export default mapping;

