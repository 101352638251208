import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { Button } from "assets/components/button";
import { ListItemLink, ListMenu } from "assets/components/list-menu";
import { Text } from "assets/components/text/Text";
import { ScreenContainer } from "assets/layout";
import { makeStyles, useTheme } from "assets/theme";
import { getText } from "assets/localization/localization";
import { View } from "react-native";
import { Divider } from "react-native-paper";

import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { PrescriptionDto } from "@digitalpharmacist/prescription-service-client-axios";

import { PrescriptionCard } from "../../components/prescription-card";
import { StoreSelector } from "../../components/store-selector";
import { MapSize } from "../../components/store-selector/types";
import { useAppStateStore } from "../../store/app-store";
import refillService from "./refill-service";
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from "./refill-store";
import { RefillStackParamList } from "./RefillNavigation";
import { RefillTestIDs } from "./RefillTestIDs";

import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useUserState } from "../../store/user-store";
import patientService from "../../api/patient-service";
import orderService from "../medications/medication-orders/order-service";
import { useOrderRefillStore } from "../medications/medication-orders/order-store";

export const RefillReview: FunctionComponent<
  PropsWithChildren<RefillReviewProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const { user } = useUserState();
  const styles = useStyles();
  const { pharmacyId, stores, getStores } = useAppStateStore();
  const {
    selectedLocationId,
    selectedMedications,
    selectedPatient,
    selectedPatientRecord,
    unableToSubmitMedications,
    updateMedicationsData,
  } = useRefillMedicationsStore();
  const {
    methodValue,
    noteValue,
    isUrgent,
    isToday,
    updateReviewMethodData,
    submitRefill,
  } = useRefillReviewMethodStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();

  const [showMethodError, setShowMethodError] = useState<boolean>(false);

  useEffect(() => {
    if (!stores.length) getStores();

    if (!selectedLocationId || !selectedPatient || !updateReviewMethodData)
      return;

    refillService
      .getLastRefill(selectedLocationId, selectedPatient.id)
      .then((data) => {
        if (!data?.fulfillment_method) return;

        updateReviewMethodData({
          methodValue: data.fulfillment_method.toString(),
        });
      });
  }, [selectedPatient]);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId
    );
    setSelectedStore(selectedStore);
  }, [stores, selectedLocationId]);

  const handleOnPressDone = async () => {
    if (methodValue === "" || methodValue === "Not selected") {
      setShowMethodError(true);
      return;
    }
    if (
      !submitRefill ||
      !selectedPatient ||
      !selectedLocationId ||
      !selectedPatientRecord ||
      !user ||
      !updateMedicationsData
    )
      return;
    await submitRefill(
      pharmacyId,
      selectedLocationId,
      selectedPatient.id,
      selectedMedications.filter((m) => m.checked),
      user.id,
      isToday,
      isUrgent
    );
    navigation.navigate("refill-confirmation");

    const allOrders = await orderService.getPatientOrders(
      selectedLocationId,
      selectedPatientRecord.id,
      user.id
    );

    useOrderRefillStore.setState({
      patientOrders: allOrders.sort((po1, po2) =>
        po1.order.created_at < po2.order.created_at ? 1 : -1
      ),
    });

    const selectedLocationPatientRecord =
      await patientService.findLocationPatientRecordByPR(
        selectedLocationId,
        selectedPatientRecord
      );

    if (!selectedLocationPatientRecord) return;

    const prescriptionResponse = await refillService.getPatientPrescriptions(
      selectedLocationId,
      selectedLocationPatientRecord.id
    );

    updateMedicationsData({
      selectedMedications: prescriptionResponse?.map((x) => ({
        ...x,
        checked: false,
      })),
    });
  };

  const handleOtherInformationNavigation = () => {
    navigation.navigate("refill-other-information");
  };

  const handlePrescriptionMethodNavigation = () => {
    navigation.navigate("refill-prescription-method");
  };

  const otherInformationText = () => {
    if (!selectedPatientRecord) return "";

    const text = [];
    if (selectedPatientRecord.prefers_easy_open_bottle_caps) {
      text.push(getText("easy-open-bottle-caps"));
    }
    if (selectedPatientRecord.allergies.length > 0) {
      text.push(
        getText("allergies") + ": " + selectedPatientRecord.allergies.join(",")
      );
    }
    if (selectedPatientRecord.medical_conditions.length > 0) {
      text.push(
        getText("medical-conditions") +
          selectedPatientRecord.medical_conditions.join(",")
      );
    }

    return text.join(", ");
  };

  const removeMedications = (item: PrescriptionDto) => {
    if (updateMedicationsData) {
      updateMedicationsData({
        selectedMedications: selectedMedications?.map((x) => ({
          ...x,
          checked: x.rx_number === item.rx_number ? false : x.checked,
        })),
        unableToSubmitMedications: unableToSubmitMedications?.filter(
          (med) => med.rx_number !== item.rx_number
        ),
      });
    }
  };

  const isMoreThenOneMedication =
    selectedMedications.filter((x) => x.checked).length > 1;

  return (
    <ScreenContainer>
      <View>
        <View style={{ marginTop: theme.getSpacing(2) }}>
          <Text style={styles.textTitle}>{getText("medications")}</Text>
        </View>
        {selectedMedications
          ?.filter((item) => item.checked)
          .map((item) => (
            <View key={item.rx_number}>
              <View style={{ paddingVertical: theme.getSpacing(2) }}>
                <Divider />
              </View>
              <PrescriptionCard
                key={item.rx_number}
                patient={selectedPatient}
                prescription={item}
                selectable={false}
                removable={isMoreThenOneMedication}
                onRemove={() => removeMedications(item)}
                check={item.checked}
              />
            </View>
          ))}
      </View>
      <View>
        <View style={styles.container}>
          <View style={{ paddingVertical: theme.getSpacing(1) }}>
            <Text style={styles.textTitle}>{getText("other-details")}</Text>
            <Divider />
          </View>
          <View style={styles.container}>
            {selectedStore && (
              <StoreSelector
                options={stores}
                defaultOption={selectedStore}
                mapProps={{ size: MapSize.md }}
                changeButtonShown={false}
              />
            )}
          </View>
        </View>
        <Divider />
        <ListMenu style={styles.container}>
          <ListItemLink onPress={handleOtherInformationNavigation}>
            <Text style={styles.textTitle}>{getText("other-information")}</Text>
            <Text>{otherInformationText()}</Text>
          </ListItemLink>
          <ListItemLink onPress={handlePrescriptionMethodNavigation}>
            <Text style={styles.textTitle}>{getText("method")}</Text>
            <Text style={{ textTransform: "capitalize" }}>{methodValue}</Text>
          </ListItemLink>
          {showMethodError && (
            <Text style={{ color: "red" }}>{getText("field-required")}</Text>
          )}
        </ListMenu>
      </View>
      <View style={styles.noteForPharmacyContainer}>
        <Text style={styles.noteForPharmacyTitle}>
          {getText("note-for-pharmacy-not-optional")}
        </Text>
        <Text>{noteValue}</Text>
      </View>
      <View>
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={RefillTestIDs.refillSubmitButton}
          logger={{ id: RefillTestIDs.refillSubmitButton }}
        >
          {getText("submit")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export interface RefillReviewProps
  extends NativeStackScreenProps<RefillStackParamList, "refill-review"> {}

const useStyles = makeStyles((theme) => ({
  displayContent: {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    fontWeight: "400",
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
  },
  root: {
    flex: 1,
  },
  rowDisplay: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  medicationTitle: {
    ...theme.fonts.medium,
    fontWeight: "600",
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  descriptionTitle: {
    ...theme.fonts.medium,
    fontWeight: "400",
    fontSize: 14,
    color: theme.palette.gray[700],
    marginVertical: theme.getSpacing(0.5),
  },
  medicationDetailsText: {
    ...theme.fonts.medium,
    fontWeight: "400",
    fontSize: 14,
    color: theme.palette.gray[700],
  },
  noteForPharmacyTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginBottom: theme.getSpacing(1),
  },
  viewItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginVertical: theme.getSpacing(1),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: "700",
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
    margin: theme.getSpacing(0.5),
  },
  row: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
    justifyContent: "space-between",
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  otherInformationModal: {
    padding: theme.getSpacing(2),
  },
  methodModal: {
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
  },
  methodOptions: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    margin: theme.getSpacing(1),
    padding: theme.getSpacing(1),
    borderRadius: theme.roundness,
  },
  insuranceModal: {
    padding: theme.getSpacing(2),
  },
  noteForPharmacyContainer: {
    borderWidth: 1,
    borderRadius: theme.roundness,
    borderColor: theme.palette.gray[100],
    backgroundColor: theme.palette.gray[100],
    display: "flex",
    alignItems: "flex-start",
    padding: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
  displayTitleContent: {
    flexDirection: "row",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
  },
}));
