import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { ScreenContainer } from "assets/layout";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text/Text";
import { getText } from "assets/localization/localization";
import { Platform, useWindowDimensions, View } from "react-native";
import { Map } from "../../../../../packages/assets/components/map";
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from "./refill-store";
import { RefillTestIDs } from "./RefillTestIDs";
import { useAppStateStore } from "../../store/app-store";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { StackHeaderProps } from "@react-navigation/stack";

export const RefillConfirmation: FunctionComponent<
  PropsWithChildren<RefillConfirmationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const handleOnPressDone = () => {
    navigation.navigate("app", { screen: "home" });
  };
  const { stores } = useAppStateStore();
  const { selectedLocationId } = useRefillMedicationsStore();
  const { submittedRefill } = useRefillReviewMethodStore();
  const { width } = useWindowDimensions();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const mapWidth =
    Platform.OS === "web"
      ? theme.webMaxWidth - 32
      : width - theme.getSpacing(4);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId
    );

    if (selectedStore) {
      setSelectedStore(selectedStore);
    }
  }, [selectedLocationId]);

  return (
    <ScreenContainer>
      <View>
        <View style={styles.container}>
          <Text style={styles.textTitle}>{getText("order-submitted")}</Text>
          <Text style={styles.textSecondary}>
            {getText("order")} {submittedRefill?.refill_id}
          </Text>
        </View>

        <View style={styles.container}>
          {selectedStore && (
            <Map
              longitude={selectedStore.longitude!}
              latitude={selectedStore.latitude!}
              width={mapWidth}
              height={250}
            />
          )}
        </View>
        <View style={{ paddingVertical: theme.getSpacing(1) }}>
          <Text style={styles.textTitle}>{selectedStore?.name}</Text>
          {selectedStore?.address && (
            <>
              <Text style={styles.textSecondary}>
                {selectedStore?.address.address1}
              </Text>
              <Text style={styles.textSecondary}>
                {selectedStore?.address.address2}
              </Text>
            </>
          )}
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          hierarchy="secondary"
          onPress={handleOnPressDone}
          testID={RefillTestIDs.refillConfirmationDone}
          logger={{ id: "refill-medication-selection-next" }}
        >
          {getText("done")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export interface RefillConfirmationProps extends StackHeaderProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 32,
    textAlign: "center",
    color: theme.palette.gray[900],
  },
  textSecondary: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 28,
    textAlign: "center",
    color: theme.palette.gray[500],
  },
  textTitleSmall: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 32,
    textAlign: "center",
    marginTop: theme.getSpacing(0.5),
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
  row: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
    textAlign: "center",
    justifyContent: "center",
  },
  icon: {
    marginTop: theme.getSpacing(1),
  },
}));
