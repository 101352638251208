import * as Localization from "expo-localization";
import i18n from "i18n-js";
import englishTranslations from "./en/translation";
import spanishTranslations from "./es/translation";
import frenchTranslations from "./fr/translation";

// future: it may be worth looking at more advanced library for things like pluralization and more features: https://medium.com/@jamuhl/translate-your-expo-io-react-native-mobile-application-aa220b2362d2
i18n.translations = {
  en: englishTranslations,
  es: spanishTranslations,
  fr: frenchTranslations,
};

i18n.locale = Localization.locale;
i18n.fallbacks = true;

export const getText = (key: translations, options?: Record<string, any>) => {
  return i18n.t(key, options);
};

export const getLocale = () => {
  return i18n.locale.substring(0, 2);
};

export type TranslationMapping = Record<translations, string>;

type translations =
  | "signup"
  | "login"
  | "username"
  | "password"
  | "dashboard"
  | "medications"
  | "messages"
  | "profile"
  | "get-care"
  | "lets-get-to-know-you"
  | "lets-get-to-know-you-details"
  | "welcome"
  | "logout"
  | "front"
  | "back"
  | "otc-medications"
  | "next"
  | "scan-barcode"
  | "scanned"
  | "save"
  | "forgot-password"
  | "back-to-login"
  | "camera-access-denied"
  | "username-is-required"
  | "password-is-required"
  | "view-full-profile"
  | "insurance-card"
  | "barcode-scanner"
  | "english"
  | "spanish"
  | "french"
  | "yes"
  | "no"
  | "continue"
  | "first-name"
  | "first-name-is-required"
  | "last-name"
  | "last-name-is-required"
  | "email-short"
  | "email"
  | "email-is-required"
  | "email-continue"
  | "enter-patient-information"
  | "mobile-number"
  | "mobile-number-is-required"
  | "birth-date"
  | "birthdate"
  | "birth-date-is-required"
  | "lets-get-started"
  | "front-of-card"
  | "back-of-card"
  | "cancel"
  | "retake"
  | "use-photo"
  | "password-must-include"
  | "at-least-8-characters"
  | "at-least-1-number"
  | "at-least-1-capital-letter"
  | "at-least-1-lowercase-letter"
  | "at-least-1-special-character"
  | "privacy-policy-error"
  | "privacy-policy-agree"
  | "privacy-policy-email-signup"
  | "pharmacy-privacy-policy"
  | "terms-and-conditions"
  | "terms-of-service"
  | "terms-of-service-error"
  | "terms-of-service-agree"
  | "terms-of-service-email-signup"
  | "and"
  | "submit"
  | "2-of-2-agreements"
  | "medical-info-step-3"
  | "password-entry-does-not-meet-criteria"
  | "password-entries-do-not-match"
  | "create-your-account"
  | "please-enter-your-new-password"
  | "email-is-not-valid"
  | "phone-is-not-valid"
  | "create-account"
  | "confirm-password"
  | "patient-info-step"
  | "communications-language"
  | "prescriptions-communications"
  | "automated-voice-call"
  | "text-message"
  | "account-setup"
  | "app-notifications"
  | "all-other-pharmacy-communications"
  | "same-time-preferences"
  | "additional-information"
  | "visually-impaired"
  | "hearing-impaired"
  | "easy-open-bottle-caps"
  | "personal-info-step-2"
  | "country"
  | "country-is-required"
  | "state"
  | "state-is-required"
  | "city"
  | "city-is-required"
  | "street-address"
  | "street-address-is-required"
  | "street-address-line-2"
  | "zip-code"
  | "zip-code-is-required"
  | "gender"
  | "gender-is-required"
  | "male"
  | "female"
  | "verification-code-resent"
  | "please-enter-code"
  | "didnt-receive-email"
  | "please-check-spam-folder"
  | "didnt-receive-text-message"
  | "phone-number-is-sms-capable"
  | "standard-text-messages-apply"
  | "resend-code"
  | "reset-password"
  | "reset-link-sent"
  | "please-enter-email"
  | "how-would-you-like-to-receive-reset-link"
  | "send-reset-link"
  | "create-new-password"
  | "for-security-reasons"
  | "update-password"
  | "password-expiring-soon"
  | "password-expire-in-7-days"
  | "update-password-now"
  | "confirm-new-password"
  | "confirm-update-password"
  | "new-password"
  | "change-password"
  | "login-with-email"
  | "login-enter-email"
  | "go-to-login"
  | "return-to-login"
  | "passwords-must-match"
  | "dismiss"
  | "load-more"
  | "loading"
  | "open-map"
  | "input-field"
  | "text-field"
  | "show-hide-password"
  | "search"
  | "select"
  | "use"
  | "no-options"
  | "email-or-password-incorrect"
  | "google-login"
  | "google-continue"
  | "google-signup"
  | "google-login-failed"
  | "unable-to-complete-registration"
  | "unable-to-update-account"
  | "unable-to-complete-verification"
  | "unable-to-resend-verification-code"
  | "error-submitting"
  | "greeting"
  | "unable-to-send-reset-link"
  | "unable-to-reset-password"
  | "store-selector-change"
  | "store-selector-search-address"
  | "store-selector-change-store"
  | "store-selector-your-store"
  | "store-selector-nearby-store"
  | "store-selector-close"
  | "store-selector-select"
  | "store-selector-no-stores-error"
  | "feedback"
  | "feedback-give-feedback"
  | "feedback-give-your-feedback"
  | "feedback-please-enter-feedback"
  | "feedback-how-to-improve-app"
  | "feedback-give-feedback-about-pharmacy"
  | "feedback-sent"
  | "feedback-thank-you"
  | "feedback-what-type-of-feedback"
  | "feedback-target-is-required"
  | "feedback-feedback"
  | "feedback-feedback-is-required"
  | "feedback-allowContact"
  | "feedback-thanks-message"
  | "feedback-reach-you-message"
  | "feedback-back-to-profile"
  | "feedback-error-occurred"
  | "feedback-contact-info"
  | "feedback-edit"
  | "feedback-email-address"
  | "feedback-phone-number"
  | "feedback-app"
  | "feedback-location"
  | "refill-card-medication"
  | "refill-card-due-date"
  | "prescription-card-day-supply"
  | "prescription-card-filled-on"
  | "prescription-card-last-filled"
  | "prescription-card-last-ordered"
  | "prescription-card-refill-due"
  | "remove"
  | "prescription-card-refills"
  | "prescription-card-dob"
  | "prescription-find-server-error"
  | "medications-fill-your-prescription"
  | "medications-ready-to-be-filled"
  | "medications-other-upcoming-refills"
  | "insurance-card-upload-error"
  | "insurance-add-your-information"
  | "insurance-take-card-picture"
  | "insurance-bring-to-store"
  | "camera-mask-label"
  | "take-photo"
  | "choose-photo"
  | "anonymous-refill"
  | "date-is-not-valid"
  | "verify-your-email"
  | "verify-your-phone"
  | "please-enter-your-information"
  | "email-address"
  | "user-not-found"
  | "invalid-confirmation-code"
  | "contact-support"
  | "patient-stores"
  | "many-failed-attempts"
  | "not-authorized"
  | "done"
  | "do-this-later"
  | "entered-prescription-manually"
  | "your-prescriptions"
  | "add"
  | "phone-number-footer"
  | "fax"
  | "digital-pharmacist"
  | "pharmacy-website"
  | "drug-name"
  | "drug-name-is-required"
  | "rx-number-is-required"
  | "rx-number"
  | "add-your-prescriptions"
  | "retrieve-your-prescriptions"
  | "other-meds"
  | "try-again"
  | "step"
  | "patient-dob"
  | "patient-dob-is-required"
  | "patient-last-name-is-required"
  | "patient-last-name"
  | "we-found-your-medications-alert-title"
  | "unable-to-locate-your-prescription-alert-title"
  | "unable-to-locate-your-prescription-alert-description"
  | "prescription-information"
  | "integrated-prescription-flow-description"
  | "change"
  | "location"
  | "locations"
  | "forms"
  | "tasks"
  | "patients"
  | "refill-submissions"
  | "refill-submissions-header"
  | "user"
  | "users"
  | "new-user"
  | "user-details"
  | "title"
  | "role"
  | "last-active"
  | "registered"
  | "activity"
  | "show"
  | "view-activity-logs"
  | "resend-invite"
  | "delete"
  | "delete-confirmation"
  | "cannot-undo-action"
  | "email-is-invalid"
  | "passwords-do-not-match"
  | "password-does-not-meet-criteria"
  | "follow-reset-password-instructions"
  | "pharmacy-reset-link-sent"
  | "need-help"
  | "set-your-preferences"
  | "find-your-store"
  | "confirm-your-store"
  | "link-expired"
  | "reset-unsuccessful"
  | "order-submitted"
  | "order"
  | "picking-up-at"
  | "ready-today"
  | "notify-you"
  | "apple-signup"
  | "apple-continue"
  | "apple-login"
  | "prescribed-for"
  | "last-filled-on"
  | "last-ordered-on"
  | "auto-refills"
  | "auto-refill"
  | "refills-left"
  | "quantity-dispensed"
  | "days-supply"
  | "directions"
  | "questions-about-medication"
  | "ask-your-pharmacist"
  | "message"
  | "refill"
  | "child"
  | "spouse"
  | "parent"
  | "grandparent"
  | "grandchild"
  | "pet"
  | "partner"
  | "other"
  | "resources"
  | "medication-guides"
  | "how-to-take"
  | "patient-education-sheets"
  | "side-effects-interactions"
  | "prescription-written"
  | "prescription-by"
  | "call-prescriber"
  | "hi"
  | "create-your-password"
  | "i-have-read-and-agree"
  | "pharmacy-terms-and-conditions"
  | "privacy-policy"
  | "terms-and-conditions-required"
  | "privacy-policy-required"
  | "new-patient"
  | "i-am-a-new-patient"
  | "patient-details"
  | "edit"
  | "date-of-birth"
  | "date-of-birth-short"
  | "phone-number"
  | "gender-text"
  | "language"
  | "conditions"
  | "easy-open-bottle"
  | "med-sync"
  | "photo-id"
  | "image"
  | "notification"
  | "app-notification"
  | "list"
  | "providers"
  | "notes"
  | "internal-use-only"
  | "continuation-disclaimer"
  | "to-get-started-choose"
  | "face-id"
  | "set-my-store"
  | "medical-equipment-immunization"
  | "services-offered"
  | "en-es-fr"
  | "languages-spoken"
  | "holiday-hours-vary"
  | "open-hours"
  | "open-today"
  | "store-hours"
  | "upload-csv"
  | "personal-info"
  | "will-pickup-prescription-today"
  | "field-required"
  | "will-pickup-prescription-urgently"
  | "order-details"
  | "method"
  | "note-for-pharmacy"
  | "note-for-pharmacy-not-optional"
  | "review"
  | "no-allergies"
  | "revoke"
  | "insurance-is-required"
  | "login-attempts-exceeded"
  | "max-attempts-exceeded"
  | "account-locked-reason"
  | "send-me-email"
  | "security-reason"
  | "send-me-sms"
  | "insurance-change-last-refill"
  | "prescription-ready-each-month"
  | "add-people-under-care-message-pt-1"
  | "add-people-under-care-message-pt-2"
  | "prescriptions-is-required"
  | "over-the-counter-medications"
  | "please-review-messages-and-proceed"
  | "how-to-proceed"
  | "enter-patient-email"
  | "add-patient-request-submit"
  | "first-name-is-not-valid"
  | "last-name-is-not-valid"
  | "provide-an-email"
  | "request-sent"
  | "revoke-access-for-authorized-caregiver"
  | "legal-guardian-confirmation-for-person"
  | "confirmation"
  | "add-minor-or-pet-patient"
  | "your-allergies"
  | "legal-guardian-agreement"
  | "meds"
  | "orders"
  | "reminders"
  | "approval-needed"
  | "person-has-an-account"
  | "add-another-person"
  | "add-another"
  | "go-home"
  | "allow-pharmacy-contact-prescriber"
  | "continue-with-selected-items"
  | "go-back"
  | "go-back-update-order"
  | "thank-you-for-choosing"
  | "add-people-under-care"
  | "add-person"
  | "return-to-order-details"
  | "we-will-contact-prescriber"
  | "unable-to-process-items"
  | "please-review-the-messages-below"
  | "ready-to-be-filled"
  | "other-details"
  | "other-information"
  | "unable-to-submit-people-under-care-request"
  | "relationship-to-me"
  | "notice"
  | "insurance"
  | "appointments"
  | "account"
  | "add-medications"
  | "add-meds-text"
  | "caregiver-approval-agreement"
  | "no-known-allergies"
  | "more-information"
  | "monthly-prescriptions"
  | "as-an-authorized-caregiver"
  | "manage-prescriptions-digitally"
  | "order-refills"
  | "delivery"
  | "mail"
  | "curbside"
  | "relation-to-primary-user"
  | "would-like-easy-open-caps"
  | "prefers-easy-open-caps"
  | "prefers-safety-caps"
  | "pick-up-prescriptions-for"
  | "discuss-care-with"
  | "manage-health-and-clinic"
  | "access-validity-puc"
  | "update-puc-list"
  | "patient-record-created"
  | "prescriptions-at-file"
  | "request-to-transfer"
  | "do-this-medications-tab"
  | "skip-this-step"
  | "using-the-phone-number-on-file"
  | "close"
  | "closed"
  | "store-info"
  | "your-password-has-been-successfully-changed"
  | "update-weak-password"
  | "people-under-your-care"
  | "people-under-your-care-empty-page"
  | "medical-info"
  | "none"
  | "add-person-or-pet"
  | "refill-details"
  | "success"
  | "prescription-no"
  | "name"
  | "status"
  | "submitted"
  | "channel"
  | "delivery-method"
  | "refill-comments"
  | "patient-info"
  | "details"
  | "phone"
  | "request-status"
  | "address"
  | "email-text"
  | "prescription"
  | "patient"
  | "date-submitted"
  | "allergies"
  | "medical-conditions"
  | "refill-past-due"
  | "refill-due"
  | "store"
  | "app"
  | "manage-prescriptions"
  | "pick-up-prescriptions"
  | "discuss-care"
  | "manage-health"
  | "add-patient"
  | "if-you-authorize-person"
  | "access-validity"
  | "revoke-access"
  | "authorized-on"
  | "revoke-access-in-settings"
  | "review-request"
  | "authorize"
  | "decline"
  | "puc-permission-access-to-records"
  | "authorized-caregivers"
  | "medications-empty-state-title"
  | "medications-empty-state-description"
  | "medications-reminders-empty-state-title"
  | "medications-reminders-empty-state-description"
  | "add-meds"
  | "transfer-meds"
  | "choose-from-the-list"
  | "select-one-item"
  | "pickup"
  | "select-an-option"
  | "please-confirm-the-info-matches-prescription-label"
  | "register-confirmation-heading"
  | "register-confirmation-body"
  | "orders-tab-empty-state-title"
  | "orders-tab-empty-state-description"
  | "start-an-order"
  | "show-locations"
  | "first-name-edit-user"
  | "last-name-edit-user"
  | "information"
  | "schedule"
  | "find-time"
  | "learn-more"
  | "next-available"
  | "not-available"
  | "intro"
  | "enter-email"
  | "create-password"
  | "register-confirmation"
  | "register"
  | "reset-link"
  | "reset-successful"
  | "register-details"
  | "phone-verification"
  | "patient-pharmacy-stores"
  | "add-prescription"
  | "refill-guest"
  | "home"
  | "settings"
  | "store-location"
  | "expiring-soon"
  | "minutes"
  | "book-appointment"
  | "upcoming"
  | "past"
  | "patient-insurance"
  | "barcode"
  | "patient-preferences"
  | "feedback-success"
  | "people-under-care"
  | "add-patient-under-care"
  | "add-patient-under-care-info"
  | "add-patient-under-care-email"
  | "add-patient-under-care-confirmation"
  | "patient-under-care-list"
  | "automatic-prescription-flow"
  | "success-automatic-prescription-flow"
  | "select-person"
  | "myself"
  | "confirm"
  | "no-refills"
  | "expired"
  | "too-soon"
  | "not-found"
  | "controlled"
  | "narcotic"
  | "transferred"
  | "discontinued"
  | "on-hold"
  | "deleted"
  | "fillable"
  | "partially-filled"
  | "out-of-stock"
  | "special-order"
  | "deceased"
  | "rejected-reason-unknown"
  | "show-more"
  | "street1"
  | "street2"
  | "gender-on-insurance"
  | "preferred-language"
  | "notification-preferences"
  | "auto-voice-call"
  | "edit-patient"
  | "show-less"
  | "appointment-scheduled"
  | "booking-error"
  | "error-loading-slots"
  | "error-loading-patient-record"
  | "error-booking-appointment"
  | "error-loading-appointments"
  | "when"
  | "who"
  | "where"
  | "care-notes"
  | "reschedule"
  | "cancel-appointment"
  | "reschedule-appointment"
  | "error-loading-appointment"
  | "error-loading-appointment-type"
  | "refill-medications"
  | "refill-medical-infos"
  | "refill-review"
  | "refill-other-information"
  | "refill-prescription-method"
  | "refill-insurance-method"
  | "refill-confirmation"
  | "refill-order-details"
  | "good-morning"
  | "good-afternoon"
  | "good-evening"
  | "you-are-chatting-with"
  | "new-message"
  | "no-messages"
  | "messages-have-a-question"
  | "new-message-placeholder"
  | "general"
  | "billing"
  | "med-info"
  | "side-effects"
  | "message-subject-label"
  | "send"
  | "appointment-rescheduled"
  | "error-rescheduling-appointment"
  | "cancel-appointment-confirmation"
  | "appointment-canceled"
  | "previously-scheduled"
  | "cancel-note"
  | "cancel-error"
  | "error-cancel-appointment"
  | "keep"
  | "of"
  | "tomorrow"
  | "today"
  | "canceled"
  | "events"
  | "error-field-number"
  | "no-appointments"
  | "no-services"
  | "video-header"
  | "videos"
  | "videos-filter"
  | "videos-clear"
  | "videos-all"
  | "videos-medication"
  | "videos-condition"
  | "videos-administration"
  | "videos-missing-medication-video"
  | "videos-general-health"
  | "videos-search"
  | "videos-share"
  | "videos-category"
  | "videos-title"
  | "videos-language-spanish"
  | "videos-language-english"
  | "videos-language-french"
  | "videos-duration"
  | "videos-url"
  | "copied-to-clipboard"
  | "videos-med-guides"
  | "videos-transcript"
  | "delete-confirmation-modal"
  | "service"
  | "create-appointment"
  | "limit-booking-frequency"
  | "limit-booking-duration"
  | "limit-future-bookings"
  | "before-event"
  | "after-event"
  | "into-future"
  | "within-date-range"
  | "add-limit"
  | "calendar-days"
  | "business-days"
  | "per-day"
  | "per-week"
  | "per-month"
  | "no-buffer-time"
  | "order-refill"
  | "content-box-title"
  | "cancellations"
  | "duration"
  | "time"
  | "check-later"
  | "actions"
  | "service-record-enabled-tooltip"
  | "service-record-disabled-tooltip"
  | "copy-link-button-tooltip"
  | "edit-service-record-tooltip"
  | "delete-service-record-tooltip"
  | "selecting-all-pages"
  | "select-all-pages"
  | "selecting-current-page"
  | "select-current-page"
  | "upcoming-refill"
  | "send-message"
  | "unselect-all"
  | "login-attempts-exceeded"
  | "max-attempts-exceeded"
  | "account-locked-reason"
  | "send-me-email"
  | "security-reason"
  | "send-me-sms"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday"
  | "good-afternoon"
  | "good-evening"
  | "others"
  | "reorder"
  | "others"
  | "generic-bulk-confirmation-modal"
  | "task"
  | "bulk-assignee-confirmation-modal"
  | "bulk-due-date-confirmation-modal"
  | "unresolved"
  | "resolved"
  | "in_progress"
  | "on_hold"
  | "task-resolve"
  | "mark-resolved"
  | "mark-in-progress"
  | "mark-flagged"
  | "mark-unresolved"
  | "mark-on-hold"
  | "change-assignee"
  | "change-due"
  | "task-in-progress"
  | "task-flag"
  | "assignee"
  | "not-started"
  | "over-the-counter"
  | "delete-task"
  | "venue"
  | "private"
  | "public"
  | "your-pharmacy"
  | "edit-availability-record-tooltip"
  | "delete-availability-record-tooltip"
  | "availability"
  | "new-chat-message"
  | "your-refill-is-ready"
  | "hi-your-refill-is-ready"
  | "patient-is-required"
  | "subject-is-required"
  | "message-is-required"
  | "message-characters-limit-five-thousand"
  | "find-template"
  | "to"
  | "subject"
  | "note-inbox-messages-should-be-clinical-in-nature"
  | "all"
  | "unread"
  | "unread-by-user"
  | "includes-me"
  | "created-by-me"
  | "last-seven-days"
  | "filters"
  | "clear"
  | "text-formatting"
  | "send-attachment"
  | "type-your-message"
  | "mask-as-unread"
  | "chat-messages"
  | "search-chats"
  | "find-patient"
  | "new-subject"
  | "add-mobile-number-send-message"
  | "add-mobile-number"
  | "newest"
  | "oldest"
  | "a-z"
  | "new-chat"
  | "inbox"
  | "auto"
  | "previous-week"
  | "current-week"
  | "next-week"
  | "secure-message"
  | "unable-to-process"
  | "try-again-later"
  | "message-pharmacy"
  | "call-pharmacy"
  | "tasks-grid-no-results-title"
  | "tasks-grid-no-results-subtitle"
  | "get-directions"
  | "explore-other-locations"
  | "error-getting-locations"
  | "zip-code-is-not-valid"
  | "street-1-is-not-valid"
  | "street-2-is-not-valid"
  | "allergies-are-not-valid"
  | "conditions-are-not-valid"
  | "make-appointment"
  | "find-service-at-location"
  | "get-more-stores"
  | "no-medication-guide"
  | "error-loading-patients-under-care"
  | "add-new-patient"
  | "after-adding-patient"
  | "pending-requests"
  | "add-calendar-event"
  | "by-application"
  | "by-email"
  | "by-sms"
  | "by-voice"
  | "error-loading-patient"
  | "in"
  | "ago"
  | "month-short"
  | "hour-short"
  | "day-short"
  | "missing-important-patient-record-data"
  | "google"
  | "apple"
  | "outlook"
  | "reset-filters"
  | "calendar"
  | "appointment-panel"
  | "age"
  | "confirmed"
  | "pending"
  | "rejected"
  | "service-description"
  | "additional-info"
  | "more"
  | "less"
  | "month"
  | "week"
  | "day"
  | "dont-see-service"
  | "service-category"
  | "team"
  | "personal"
  | "ordered"
  | "reschedule-note"
  | "characters-count"
  | "maxim-count-attachments"
  | "selecting-files-wrong"
  | "files-sending-error-try-again"
  | "home-account-subheading"
  | "home-medications-subheading"
  | "home-messages-subheading"
  | "home-appointments-subheading"
  | "home-puc-subheading"
  | "create-form-first"
  | "no-allergies-2"
  | "forms-fill-out"
  | "health-info"
  | "insurance-card-reminder"
  | "pre-appointment-checklist"
  | "error-updating-appointment"
  | "no-forms-required"
  | "form-unavailable"
  | "form-not-started"
  | "form-submitted"
  | "preparing-submission"
  | "download-will-begin"
  | "delete-submission-confirmation"
  | "not-entered"
  | "submitted-on"
  | "download-pdf"
  | "submission-modal"
  | "checklist-complete"
  | "checklist-incomplete";
