import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { PRESCRIPTION_API_BASE_URL } from "../../common/constants";
import {
  PatientApi as PrescriptionPatientClient,
  IntegrationApi as PrescriptionIntegrationClient,
  CreateRefillDto,
  RefillDto,
  LocationConfigDto,
  IntegrationType,
  PrescriptionDto,
  RefillStatus,
  CreatePrescriptionDto,
} from "@digitalpharmacist/prescription-service-client-axios";
import { Medication } from "api";
import { TypeaheadBaseItem } from "assets/components/typeahead/types";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { getText } from "assets/localization/localization";
import { ResourceItem } from "./refill-store";

// TODO KD: use the patient-service future DTO definition
export type PatientMedicalInfos = {
  patient_id: string;
  gender: "male" | "female";
  allergies: ResourceItem[];
  medicationSync: boolean;
  medicalConditions: ResourceItem[];
  easyBottleCaps: boolean;
};

// TODO: use the patient-service future DTO definition
export type PatientMedicalRefillInfos = {
  selectedMedications: Medication[];
  selectedStore: PharmacyLocationDto;
  method: string;
  insurance: string;
};

// TODO: use the patient-service future DTO definition
export type PatientMedicationDetailsInfos = {
  id: string;
  name: string;
  dob: string;
  rxNumber: string;
  lastFill: string;
  autoRefill: string;
  refillsLeft: number;
  quantityDispensed: number;
  daysSupply: number;
  directions: string;
  prescriptionWritten: string;
  prescriptionBy: string;
  locationId: string;
  ndc: string;
};

export type PrescriptionDeleteReason = {
  id: string;
  description: string;
};

export type PatientInsuranceImages = {
  id: string;
  description: string;
  url: string;
};

export interface IRefillService {
  getPatientPrescriptions(
    location_id: string,
    patient_id: string
  ): Promise<PrescriptionDto[]>;

  getPatientPrescription(
    id_or_rx: string,
    location_id: string,
    patient_id: string
  ): Promise<PrescriptionDto>;

  searchPrescriptionsAutomatically(
    location_id: string,
    patient_location_id: string,
    last_name: string,
    dob: string,
    rx_number: string
  ): Promise<PrescriptionDto>;

  createPatientPrescriptions(
    location_id: string,
    patient_id: string,
    prescription: CreatePrescriptionDto
  ): Promise<PrescriptionDto>;

  submitPatientRefill(
    location_id: string,
    patient_user_id: string,
    refill: CreateRefillDto
  ): Promise<RefillDto>;

  getLocationConfig(location_id: string): Promise<LocationConfigDto>;

  isLocationIntegrated(location_id: string): Promise<boolean>;

  getInsuranceImages(
    location_id: string,
    patient_id: string
  ): Promise<PatientInsuranceImages[]>;

  getPrescriptionDeleteReasons(): Promise<PrescriptionDeleteReason[]>;

  deletePatientPrescription(
    location_id: string,
    prescription_id: string,
    delete_reason_id: string
  ): Promise<void>;

  searchAllergies(searchTerm: string): Promise<TypeaheadBaseItem[]>;

  searchMedicalConditions(searchTerm: string): Promise<TypeaheadBaseItem[]>;

  getLastRefill(
    location_id: string,
    patient_id: string
  ): Promise<RefillDto | undefined>;

  getOrderRefill(
    location_id: string,
    patient_id: string,
    order_id: string
  ): Promise<RefillDto | undefined>;
}

export class RefillService extends BaseApiService implements IRefillService {
  private readonly prescriptionClient: PrescriptionPatientClient;
  private readonly integrationClient: PrescriptionIntegrationClient;

  public readonly topAllergiesHints = [
    { text: "Penicillin", value: "Penicillin" },
    { text: "Sulfa drugs", value: "Sulfa drugs" },
    { text: "Aspirin / ibuprofen", value: "Aspirin / ibuprofen" },
    { text: "Latex", value: "Latex" },
    { text: "Eggs", value: "Eggs" },
    { text: "Shellfish", value: "Shellfish" },
  ];

  public readonly topMedicalConditionsHints = [
    { text: "Heart disease", value: "Heart disease" },
    { text: "Cancer", value: "Cancer" },
    { text: "Respiratory disease", value: "Respiratory disease" },
    { text: "Diabetes", value: "Diabetes" },
    { text: "Alzheimer's", value: "Alzheimer's" },
    { text: "Kidney disease", value: "Kidney disease" },
  ];

  public readonly methodOptions = ["pickup", "delivery", "mail", "curbside"];

  public readonly pickupQuestions = [
    {
      text: getText("will-pickup-prescription-today"),
      name: "pickupPrescriptionToday",
      options: [
        {
          text: getText("yes"),
          value: "yes",
        },
        {
          text: getText("no"),
          value: "no",
        },
      ],
    },
    {
      text: getText("will-pickup-prescription-urgently"),
      name: "pickupPrescriptionUrgently",
      options: [
        {
          text: getText("yes"),
          value: "yes",
        },
        {
          text: getText("no"),
          value: "no",
        },
      ],
    },
  ];

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.prescriptionClient = new PrescriptionPatientClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
    this.integrationClient = new PrescriptionIntegrationClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async searchAllergies(searchTerm: string): Promise<TypeaheadBaseItem[]> {
    // TODO: This is a fake endpoint as we dont have the allergies endpoint at this moment

    const url = `https://api-suggest.rxwiki.com/v1/meds/${searchTerm}`;
    const { data } = await (await fetch(url)).json();
    return data.map((x: any) => ({ text: x.STR, value: x.STR }));
  }

  async searchMedicalConditions(
    searchTerm: string
  ): Promise<TypeaheadBaseItem[]> {
    // TODO: This is a fake endpoint as we dont have the MedicationCondition endpoint at this moment

    const url = `https://api-suggest.rxwiki.com/v1/meds/${searchTerm}`;
    const { data } = await (await fetch(url)).json();
    return data.map((x: any) => ({ text: x.STR, value: x.STR }));
  }

  async createPatientPrescriptions(
    location_id: string,
    patient_id: string,
    prescription: CreatePrescriptionDto
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionCreateOrUpdate(
      patient_id,
      location_id,
      prescription
    );

    return response.data;
  }

  async getPatientPrescriptions(
    location_id: string,
    patient_id: string
  ): Promise<PrescriptionDto[]> {
    const response = await this.prescriptionClient.prescriptionFindAll(
      patient_id,
      location_id
    );

    return response.data.sort((a, b) =>
      (a.next_refill_date &&
        b.next_refill_date &&
        a.next_refill_date < b.next_refill_date) ||
      a.created_at > b.created_at
        ? 1
        : -1
    );
  }

  async getPatientPrescription(
    id_or_rx: string,
    location_id: string,
    patient_id: string
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionFindOne(
      id_or_rx,
      patient_id,
      location_id
    );

    return response.data;
  }

  async searchPrescriptionsAutomatically(
    location_id: string,
    last_name: string,
    date_of_birth: string,
    rx_number: string
  ): Promise<PrescriptionDto> {
    const response = await this.prescriptionClient.prescriptionGetByRx(
      rx_number,
      last_name,
      date_of_birth,
      location_id
    );

    return response.data;
  }

  async checkPatientPrescriptions(
    selectedMedications: PrescriptionDto[],
    location_id: string,
    patient_id: string
  ): Promise<PrescriptionDto[]> {
    if (!selectedMedications.length) return [];

    const response = await this.prescriptionClient.prescriptionFindAll(
      patient_id,
      location_id
    );

    return response.data.filter(
      (prescription) =>
        prescription.refill_status !== RefillStatus.Fillable &&
        prescription.refill_status !== RefillStatus.Success &&
        selectedMedications.find(
          (s) => s.prescription_id === prescription.prescription_id
        )
    );
  }

  async getLastRefill(
    location_id: string,
    patient_id: string
  ): Promise<RefillDto | undefined> {
    const response = await this.prescriptionClient.refillsFind(
      location_id,
      [patient_id],
      undefined,
      undefined,
      1,
      0,
      "-created_at"
    );

    if (response.data.length) {
      return response.data[0];
    }
  }

  async getOrderRefill(
    location_id: string,
    patient_id: string,
    order_id: string
  ): Promise<RefillDto | undefined> {
    const response = await this.prescriptionClient.refillsFind(
      location_id,
      [patient_id],
      undefined,
      order_id,
      1,
      0,
      "-created_at",
      true,
      true
    );

    if (response.data.length) {
      return response.data[0];
    }
  }

  async getPrescriptionDeleteReasons() {
    return [
      {
        id: "1",
        description: "I picked it up somewhere else",
      },
      {
        id: "2",
        description: "My dosage changed",
      },
      {
        id: "3",
        description: "I still have some remaining",
      },
      {
        id: "4",
        description: "Other",
      },
    ];
  }

  async deletePatientPrescription(
    location_id: string,
    prescription_id: string,
    delete_reason_id: string
  ): Promise<void> {
    return undefined;
  }

  async submitPatientRefill(
    location_id: string,
    patient_id: string,
    refill: CreateRefillDto
  ) {
    const response = await this.prescriptionClient.refillsCreate(location_id, {
      ...refill,
      // TODO KD: replace the remove the following vars
      patient_id: patient_id,
    });

    // TODO KD: SHOW ERROR Dialog when the response.refill_items has response.refill_items[*].rx_status !== RefillItemStatus.Success
    // TODO: uncomment these when response.refill_items[*].rx_status is provided in the SDK
    // if (response.data.filter((item) => item.rx_status !== RefillItemStatus.Success).length > 0) {
    //   useRefillMedicationsStore.setState({
    //     error: { message: "error with patient prescriptions" },
    //     status: "error",
    //   });
    // }
    return response.data;
  }

  async getInsuranceImages(
    location_id: string,
    patient_id: string
  ): Promise<PatientInsuranceImages[]> {
    return [
      {
        id: "1",
        description: "Primary",
        url: "https://picsum.photos/200",
      },
      {
        id: "2",
        description: "Secondary",
        url: "https://picsum.photos/200",
      },
    ];
  }

  async getLocationConfig(location_id: string) {
    const response = await this.integrationClient.locationConfigFind(
      location_id
    );

    return response.data;
  }

  async isLocationIntegrated(location_id: string): Promise<boolean> {
    const response = await this.integrationClient.locationConfigFind(
      location_id
    );

    return response.data.integration_type === IntegrationType.Integrated;
  }
}

export default new RefillService(PRESCRIPTION_API_BASE_URL, undefined, false);
