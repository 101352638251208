import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {Form, ScreenContainer} from "assets/layout";
import {makeStyles, useTheme} from "assets/theme";
import {View} from "react-native";
import {getText} from "assets/localization/localization";
import {Divider} from "react-native-paper";
import type {NativeStackScreenProps} from "@react-navigation/native-stack";
import {Gender, PatientRecordDto} from "@digitalpharmacist/patient-service-client-axios";

import {Button} from "assets/components/button";
import {CheckboxField} from "assets/components/checkbox";
import {RadioButtonGroupField} from "assets/components/radio-button-group";
import {Text} from "assets/components/text/Text";
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from "assets/components/typeahead-with-tags";
import {useForm} from "assets/form";

import patientService from "../../api/patient-service";
import refillService from "./refill-service";
import {
  useRefillMedicalInformationStore,
  useRefillMedicationsStore,
} from "./refill-store";
import {RefillStackParamList} from "./RefillNavigation";
import {RefillTestIDs} from "./RefillTestIDs";
import {useUserState} from "../../store/user-store";

export const RefillMedicalInfos: FunctionComponent<
  PropsWithChildren<RefillMedicalInfosProps>
> = ({navigation}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {patientMedicalInfos} = useRefillMedicalInformationStore();
  const {selectedMedications, selectedPatientRecord, updateMedicationsData} = useRefillMedicationsStore();
  const {user} = useUserState();

  const form = useForm({
    defaultValues: {...patientMedicalInfos},
  });

  useEffect(() => {
    if (!user?.patientRecordId || !selectedPatientRecord) return;

    const allergies =
      selectedPatientRecord.allergies === undefined
        ? []
        : selectedPatientRecord.allergies.length == 0
          ? [{value: "no_allergies", text: ""}]
          : selectedPatientRecord.allergies.map((v) => ({value: v, text: v}));

    form.setValue("gender", selectedPatientRecord.gender || "");
    form.setValue("allergies", allergies);
    form.setValue(
      "medicalConditions",
      selectedPatientRecord.medical_conditions.map((v) => ({
        value: v,
        text: v,
      }))
    );
    form.setValue(
      "easyBottleCaps",
      selectedPatientRecord.prefers_easy_open_bottle_caps || false
    );
    form.setValue(
      "prescriptionMonthly",
      selectedPatientRecord.prefers_med_sync ? "yes" : "no"
    );
  }, [user?.patientRecordId, selectedPatientRecord]);

  const handleOnPressDone = async () => {
    if (!user?.patientRecordId) return;

    setIsLoading(true);
    const patientMedicalInfos = {
      id: user.patientRecordId,
      gender: form.getValues().gender as Gender,
      allergies: form.getValues().allergies.map((v) => v.value),
      medical_conditions: form
        .getValues()
        .medicalConditions.map((v) => v.value)
        .filter((v) => v !== "no_allergies"),
      prefers_med_sync: form.getValues().prescriptionMonthly === "yes",
      prefers_easy_open_bottle_caps: form.getValues().easyBottleCaps,
    };

    if (updateMedicationsData) {
      updateMedicationsData({
        selectedPatientRecord: {
          ...selectedPatientRecord,
          gender: patientMedicalInfos.gender,
          allergies: patientMedicalInfos.allergies,
          medical_conditions: patientMedicalInfos.medical_conditions,
          prefers_med_sync: patientMedicalInfos.prefers_med_sync,
          prefers_easy_open_bottle_caps: patientMedicalInfos.prefers_easy_open_bottle_caps
        } as PatientRecordDto,
        selectedMedications: selectedMedications,
      })
    }

    patientService
      .updatePatientRecord(patientMedicalInfos.id, patientMedicalInfos)
      .then(() => {
        setIsLoading(false);
        navigation.navigate("refill-review");
      })
      .catch((err) => {
        setIsLoading(false);
        useRefillMedicalInformationStore.setState({
          error: {message: err}, // TODO: change this to specific text
          status: "error",
        });
      });
  };

  const allergiesFieldProps: TypeaheadWithTagsFieldProps = {
    name: "allergies",
    rules: {required: {value: true, message: "This field is required *"}},
    typeaheadWithTagsProps: {
      label: "Allergies *",
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchAllergies(searchTerm);
      },
      hintMessage: "Your allergies",
      emptyValue: {text: "No known allergies", value: "no_allergies"},
      tags: refillService.topAllergiesHints,
    },
  };

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    // testID: RefillTestIDs.medicalConditionsInput,
    name: "medicalConditions",
    typeaheadWithTagsProps: {
      label: "Medical conditions",
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: "Your conditions",
      tags: refillService.topMedicalConditionsHints,
    },
  };

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>{getText("additional-information")}</Text>
      <Divider/>
      <Form methods={form}>
        <Form.Row>
          <Form.Column style={{marginTop: theme.getSpacing(3)}}>
            <RadioButtonGroupField
              label={getText("gender")}
              rules={{
                required: getText("gender-is-required"),
              }}
              name="gender"
              values={[
                {
                  text: getText("male"),
                  value: "male",
                },
                {
                  text: getText("female"),
                  value: "female",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...allergiesFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText("prescription-ready-each-month")}
              rules={{
                required: getText("prescriptions-is-required"),
              }}
              name="prescriptionMonthly"
              values={[
                {
                  text: getText("yes"),
                  value: "yes",
                },
                {
                  text: getText("no"),
                  value: "no",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>

        <Form.Row>
          <Form.Column>
            <Text style={{marginVertical: theme.getSpacing(1)}}>
              More information
            </Text>
            <View style={{marginVertical: theme.getSpacing(1)}}>
              <CheckboxField
                label={getText("easy-open-bottle-caps")}
                testID={RefillTestIDs.allergiesCheckbox}
                name="easyBottleCaps"
              />
            </View>
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            hierarchy="primary"
            disabled={isLoading}
            onPress={form.handleSubmit(handleOnPressDone)}
            testID={RefillTestIDs.refillMedicalInfosSubmit}
            logger={{id: RefillTestIDs.refillMedicalInfosSubmit}}
          >
            {getText("next")}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

export interface RefillMedicalInfosProps
  extends NativeStackScreenProps<
    RefillStackParamList,
    "refill-medical-infos"
  > {
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
}));
