import { makeStyles, useTheme } from "assets/theme";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { Button } from "assets/components/button";
import { KeyboardAvoidingView, View } from "react-native";
import { Text } from "assets/components/text/Text";
import { TextInput } from "assets/components/text-field/TextInput";
import { Divider } from "react-native-paper";
import { RefillGuestTestIDs } from "../../refill-guest/RefillGuestTestIDs";
import { useAppStateStore } from "../../../store/app-store";
import { StoreSelector } from "../../../components/store-selector";
import { ScreenContainer } from "assets/layout";
import { RefillStackParamList } from "../RefillNavigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { MapSize } from "../../../components/store-selector/types";
import { PickupHandler } from "./pickup-bottom-sheet/PickupProps";
import { Pickup } from "./pickup-bottom-sheet/Pickup";
import { getText } from "assets/localization/localization";
import {
  useRefillMedicationsStore,
  useRefillReviewMethodStore,
} from "../refill-store";
import refillService from "../refill-service";
import { RadioButtonGroupInput } from "assets/components/radio-button-group/RadioButtonGroupInput";
import {FulfillmentMethod} from "@digitalpharmacist/prescription-service-client-axios";

// TODO: Klajd use get text for fulfillment method
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const ChoosePrescriptionMethod: FunctionComponent<
  PropsWithChildren<ChoosePrescriptionMethodNavigationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const [methodOptions,setMethodOptions] = useState<FulfillmentMethod[]>([])
  const { selectedLocationId } = useRefillMedicationsStore();
  const { stores, getStores } = useAppStateStore();
  const { noteValue, methodValue, updateReviewMethodData } =
    useRefillReviewMethodStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [allowPickupToday, setAllowPickupToday] = useState<boolean>(false);
  const [allowPickupUrgent, setAllowPickupUrgent] = useState<boolean>(false);

  useEffect(() => {
    if (!stores.length) getStores();

    if (!selectedLocationId || !updateReviewMethodData) return;

    (async () => {
      const locationConfig = await refillService.getLocationConfig(
        selectedLocationId
      );

      setAllowPickupToday(locationConfig.allowPickupToday);
      setAllowPickupUrgent(locationConfig.allowPickupUrgent);

      if(locationConfig.fulfilment_methods){
        setMethodOptions(locationConfig.fulfilment_methods)
      }

      if (locationConfig.fulfilment_methods?.length == 1) {
        updateReviewMethodData({
          methodValue: locationConfig.fulfilment_methods[0],
        });
      }
    })();
  }, []);

  useEffect(() => {
    const selectedStore = stores.find(
      (store) => store.id === selectedLocationId
    );
    setSelectedStore(selectedStore);
  }, [stores, selectedLocationId]);
  const pickupRef = React.useRef<PickupHandler>(null);

  const handleShow = () => {
    pickupRef.current?.show();
  };

  const handlePickupSubmit = (values: any) => {
    if (updateReviewMethodData) {
      updateReviewMethodData({
        methodValue: "pickup",
        isToday: values?.pickupPrescriptionToday === "yes",
        isUrgent: values?.pickupPrescriptionUrgently === "yes",
      });
    }
  };

  const handleChange = (newValue: string) => {
    if (updateReviewMethodData) {
      updateReviewMethodData({ methodValue: newValue });

      if (newValue === "pickup" && (allowPickupToday || allowPickupUrgent)) {
        handleShow();
      }
    }
  };

  return (
    <ScreenContainer>
      <KeyboardAvoidingView behavior="position">
        <View style={{ paddingVertical: theme.getSpacing(1) }}>
          <View style={styles.container}>
            <Text style={styles.textTitle}>{getText("order-details")}</Text>
          </View>
          <Divider />
          <View style={{ paddingTop: theme.getSpacing(1) }}>
            <View style={styles.container}>
              <View style={styles.row}>
                <StoreSelector
                  options={stores}
                  defaultOption={selectedStore}
                  onChange={setSelectedStore}
                  mapProps={{ size: MapSize.md, zoom: 8 }}
                  changeButtonShown={false}
                />
              </View>
            </View>
          </View>
          <View style={styles.container}>
            <Text style={styles.textTitle}>{getText("method")}*</Text>

            <RadioButtonGroupInput
              values={methodOptions.map((x) => ({
                text: capitalizeFirstLetter(x),
                value: x,
              }))}
              value={methodValue}
              onValueChange={(newValue) => handleChange(newValue)}
            />
          </View>
        </View>
        <Pickup
          ref={pickupRef}
          handlePickupSubmit={handlePickupSubmit}
          allowPickupToday={false}
          allowPickupUrgent={allowPickupUrgent}
        />
        <View style={{ marginBottom: theme.getSpacing(3) }}>
          <TextInput
            multiline
            numberOfLines={3}
            value={noteValue}
            placeholder={getText("note-for-pharmacy")}
            onChange={(text) => {
              if (updateReviewMethodData) {
                updateReviewMethodData({
                  methodValue: methodValue,
                  noteValue: text,
                });
              }
            }}
          />
        </View>
        <View>
          <Button
            hierarchy="primary"
            onPress={() => navigation.navigate("refill-review")}
            disabled={methodValue === "" || methodValue === "Not selected"}
            testID={RefillGuestTestIDs.refillGuestFindStoreNext}
            logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
          >
            {getText("review")}
          </Button>
        </View>
      </KeyboardAvoidingView>
    </ScreenContainer>
  );
};

interface ChoosePrescriptionMethodNavigationProps
  extends NativeStackScreenProps<
    RefillStackParamList,
    "refill-insurance-method"
  > {}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: "700",
    fontSize: 16,
    margin: theme.getSpacing(0.5),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
    margin: theme.getSpacing(0.5),
  },
  row: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
    justifyContent: "space-between",
  },
  rowCheckbox: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  methodOptions: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    marginVertical: theme.getSpacing(1),
    paddingVertical: theme.getSpacing(1),
    borderRadius: theme.roundness,
  },
  textArea: {
    borderWidth: 1,
    borderColor: theme.palette.gray[300],
    marginVertical: theme.getSpacing(1),
    padding: theme.getSpacing(2),
    borderRadius: theme.roundness,
  },
}));
