import React, { FunctionComponent } from "react";
import { BottomTabs } from "assets/components/bottom-tabs";
import { Dashboard } from "../screens/dashboard/Dashboard";
import { Icon } from "assets/components/icon";
import {
  HomeIcon,
  MedicationIcon,
  MessageSquareIcon,
  CalendarIcon,
  AccountCircleIcon,
} from "assets/icons";
import { Messages } from "../screens/messages/Messages";
import { RootNavigationTestIDs } from "./RootNavigationTestIDs";
import { AccountNavigation } from "../screens/account/AccountNavigation";
import { getText } from "assets/localization/localization";
import { FillInForm } from "../screens/fill-in-form/FillInForm";
import { MedicationsNavigation } from "../screens/medications/MedicationsNavigation";
import { AppointmentsNavigation } from "../screens/appointments/AppointmentsNavigation";
import { AppointmentDetails } from "../screens/appointment/AppointmentDetails";
import {OrderDetails} from "../screens/medications/medication-orders/OrderDetails";

export const BottomTabsNavigation: FunctionComponent = () => {
  return (
    <BottomTabs
      screens={[
        {
          name: "home",
          label: getText("home"),
          component: Dashboard,
          icon: ({ color, size }) => (
            <Icon icon={HomeIcon} color={color} size={size} />
          ),
          testID: RootNavigationTestIDs.dashboard,
        },
        {
          name: "medications",
          label: getText("medications"),
          component: MedicationsNavigation,
          icon: ({ color, size }) => (
            <Icon icon={MedicationIcon} color={color} size={size} />
          ),
          testID: RootNavigationTestIDs.medications,
        },
        {
          name: "messages",
          label: getText("messages"),
          component: Messages,
          icon: ({ color, size }) => (
            <Icon icon={MessageSquareIcon} color={color} size={size} />
          ),
          testID: RootNavigationTestIDs.messages,
        },
        {
          name: "appointments",
          label: getText("appointments"),
          component: AppointmentsNavigation,
          icon: ({ color, size }) => (
            <Icon icon={CalendarIcon} color={color} size={size} />
          ),
          testID: RootNavigationTestIDs.appointments,
        },
        {
          name: "account",
          label: getText("account"),
          component: AccountNavigation,
          icon: ({ color, size }) => (
            <Icon icon={AccountCircleIcon} color={color} size={size} />
          ),
          testID: RootNavigationTestIDs.account,
        },
        // The forms screen should be a part of the `app` parent route
        // but we don't want to show a direct link in the bottom nav
        {
          name: "forms",
          label: "",
          component: FillInForm,
          testID: RootNavigationTestIDs.forms,
          options: {
            tabBarItemStyle: {
              display: "none",
            },
          },
        },
        {
          name: "appointment",
          label: "",
          component: AppointmentDetails,
          testID: RootNavigationTestIDs.appointment,
          options: {
            tabBarItemStyle: {
              display: "none",
            },
          },
        },
        {
          name: "order-details",
          label: "",
          component: OrderDetails,
          testID: RootNavigationTestIDs["order-details"],
          options: {
            tabBarItemStyle: {
              display: "none",
            },
          },
        },
      ]}
    />
  );
};
