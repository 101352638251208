import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
  useIsFocused,
} from "@react-navigation/native";
import { NativeHeader } from "assets/components/native-header";
import { CloseIcon } from "assets/icons";
import { AppointmentsScreenRouteProp } from "../../navigation/RootNavigation";
import {
  getAppointmentType,
  getBooking,
  setAppointmentType,
  setBooking,
  setRefresh,
  setShowCancelAppointment,
  updateBooking,
} from "./appointment-details-actions";
import { useAppStateStore } from "../../store/app-store";
import { useAppointmentDetailsState } from "./appointment-details-store";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { formatDateTimeWithTimezone } from "../../common/datetime-utils";
import { DEFAULT_UTC_OFFSET } from "../appointments/book-appointment/book-appointment-utils";
import { Button } from "assets/components/button";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import { makeStyles, useTheme } from "assets/theme";
import { AppointmentDetailsInfo } from "./AppointmentDetailsInfo";
import { ScreenContainer } from "assets/layout";
import { getText } from "assets/localization/localization";
import {
  setShowBookAppointment,
  setAppointmentType as setAppointmentTypeBooking,
  setIsReschedule,
  setBooking as setModalBooking,
  setSelectedDate,
  setSelectedPatient,
  setLocationOverride,
} from "../appointments/book-appointment/book-appointment-actions";
import moment from "moment";
import { BookAppointment } from "../appointments/book-appointment/BookAppointment";
import { CancelAppointment } from "./CancelAppointment";
import { BookingDto } from "@digitalpharmacist/appointment-service-client-axios";
import {
  AppointmentDetailsBadge,
  AppointmentStatus,
} from "./AppointmentDetailsBadge";
import patientService from "../../api/patient-service";
import { logError } from "assets/logging/logger";
import {
  PatientRecordDto,
  RecordUnderCareDto,
} from "@digitalpharmacist/patient-service-client-axios";
import { CheckboxInput, CheckboxInputMode } from "assets/components/checkbox";
import { FormStatus } from "@digitalpharmacist/forms-service-client-axios";
import { AppointmentChecklistBadge } from "./AppointmentChecklistBadge";

export const AppointmentDetails: FunctionComponent<AppointmentDetailsProps> = (
  props
) => {
  const navigation = useNavigation<AppointmentsScreenRouteProp>();
  const route = useRoute<any>();
  const appointmentIdParam = route.params.appointment_id;
  const locationIdParam = route.params.location_id;
  const isFocused = useIsFocused();
  const { pharmacyId, appointmentLocation } = useAppStateStore((state) => ({
    ...state,
    appointmentLocation: state.stores.find(
      (store) => store.id === locationIdParam
    ),
  }));
  const {
    booking,
    appointmentType,
    type,
    status,
    appointmentTypeStatus,
    refresh,
    checkListStatus,
  } = useAppointmentDetailsState();

  const validFormsCount = useAppointmentDetailsState(
    (state) =>
      state.appointmentType?.forms.filter(
        (form) => form.form_status === FormStatus.Enabled
      ).length ?? 0
  );

  const styles = useStyles();
  const theme = useTheme();
  const [patient, setPatient] =
    useState<PatientRecordDto | RecordUnderCareDto>();
  const [patientLoading, setPatientLoading] = useState<boolean>(false);

  const isChecklistComplete = !!(
    booking?.bring_insurance_card &&
    booking.review_related_health_info &&
    booking.submissions.length === validFormsCount
  );

  useFocusEffect(
    useCallback(() => {
      getBookingData();

      return () => {
        setBooking(undefined);
        setAppointmentType(undefined);
      };
    }, [route])
  );

  useEffect(() => {
    if (booking) {
      setPatientLoading(true);

      getAppointmentType(
        pharmacyId,
        booking.location_id,
        booking.appointment_type_id
      );

      patientService
        .findPatientRecord(booking.patient_record_id)
        .then((patientRecord) => {
          setPatient(patientRecord);
        })
        .catch((error) => logError(error))
        .finally(() => setPatientLoading(false));
    }
  }, [booking]);

  useEffect(() => {
    if (refresh) {
      getBookingData();
      setRefresh(false);
    }
  }, [refresh]);

  const getBookingData = () => {
    if (appointmentIdParam && locationIdParam) {
      getBooking(pharmacyId, locationIdParam, appointmentIdParam);
    }
  };

  const handleReschedule = () => {
    setBookAppointmentValues();
    setShowBookAppointment(true);
  };

  const setBookAppointmentValues = () => {
    setAppointmentTypeBooking(appointmentType);
    setIsReschedule(true);
    setModalBooking(booking);
    setSelectedDate(moment(booking?.startTime).format("YYYY-MM-DD"));
    setSelectedPatient(patient);
    setLocationOverride(appointmentLocation);
  };

  const handleUpdateBooking = (field: Record<string, boolean>) => {
    if (!booking || !locationIdParam) return;

    const bookingData = {
      ...booking,
      ...field,
    };

    updateBooking(pharmacyId, locationIdParam, booking.id, bookingData);
  };

  const getStatus = (booking: BookingDto): AppointmentStatus => {
    if (booking.status === "CANCELLED") {
      return "canceled";
    }

    if (type === "past") {
      return "past";
    }

    if (moment(booking.startTime).isSame(moment(), "day")) {
      return "today";
    }

    if (moment(booking.startTime).isSame(moment().add(1, "day"), "day")) {
      return "tomorrow";
    }

    return "upcoming";
  };

  return (
    <>
      <NativeHeader
        actionIcon={CloseIcon}
        onBack={() => navigation.navigate("appointments")}
        showAction={false}
        showBack={true}
        title={"Appointment details"}
      />
      <ScreenContainer>
        {isFocused && (
          <BookAppointment onDismiss={() => setShowBookAppointment(false)} />
        )}

        {status === "loading" || !booking ? (
          <View style={{ marginTop: theme.getSpacing(4) }}>
            <LoadingIndicator />
          </View>
        ) : (
          <>
            <CancelAppointment booking={booking} />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{booking.title}</Text>

              <AppointmentDetailsBadge status={getStatus(booking)} />
            </View>
            {type === "upcoming" && booking.status !== "CANCELLED" ? (
              <AppointmentChecklistBadge complete={isChecklistComplete} />
            ) : null}
            <Text style={styles.description}>{booking.description}</Text>
            <AppointmentDetailsInfo label={getText("when")}>
              <Text style={styles.infoText}>
                {formatDateTimeWithTimezone(
                  booking.startTime,
                  DEFAULT_UTC_OFFSET,
                  "dddd, MMMM D, YYYY"
                )}
              </Text>
              <Text style={styles.infoText}>
                {formatDateTimeWithTimezone(
                  booking.startTime,
                  DEFAULT_UTC_OFFSET,
                  "hh:mm A"
                )}{" "}
                -{" "}
                {formatDateTimeWithTimezone(
                  booking.endTime,
                  DEFAULT_UTC_OFFSET,
                  "hh:mm A"
                )}{" "}
                CT
              </Text>
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo label={getText("who")}>
              <Text style={styles.infoText}>
                {booking.patient_record_first_name}{" "}
                {booking.patient_record_last_name}
              </Text>
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo label={getText("where")}>
              {!appointmentLocation ? (
                <LoadingIndicator size={24} />
              ) : (
                <>
                  <Text style={styles.infoText}>
                    {appointmentLocation.name}
                  </Text>
                  {appointmentLocation.address?.address1 && (
                    <>
                      <Text
                        style={[
                          styles.infoText,
                          { color: theme.palette.gray[500] },
                        ]}
                      >
                        {appointmentLocation.address.address1}
                        {appointmentLocation.address.address2 &&
                          `, ${appointmentLocation.address.address2}`}
                      </Text>
                      <Text
                        style={[
                          styles.infoText,
                          { color: theme.palette.gray[500] },
                        ]}
                      >
                        {appointmentLocation.address.city},{" "}
                        {appointmentLocation.address.state}{" "}
                        {appointmentLocation.address.postal_code}
                      </Text>
                    </>
                  )}
                </>
              )}
            </AppointmentDetailsInfo>
            <AppointmentDetailsInfo
              label={getText("pre-appointment-checklist")}
              containerStyle={styles.checkboxContainer}
              labelStyle={styles.checkboxLabel}
            >
              {!appointmentLocation || appointmentTypeStatus === "loading" ? (
                <LoadingIndicator size={24} />
              ) : (
                <View style={styles.checkboxContentContainer}>
                  {validFormsCount && validFormsCount > 0 ? (
                    <CheckboxInput
                      // FIXME: This is a temporary workaround until we  are able to receive the submission ids from the back-end and check if all currently available forms are submitted
                      checked={validFormsCount === booking.submissions.length}
                      onPress={(checked) => null}
                      label={getText("forms-fill-out", {
                        formsCount: validFormsCount,
                      })}
                      mode={CheckboxInputMode.FLAT}
                      disabled
                    />
                  ) : null}
                  <CheckboxInput
                    checked={!!booking.review_related_health_info}
                    onPress={(checked) =>
                      handleUpdateBooking({
                        review_related_health_info: checked,
                      })
                    }
                    label={getText("health-info")}
                    mode={CheckboxInputMode.FLAT}
                    disabled={
                      checkListStatus === "loading" ||
                      type !== "upcoming" ||
                      booking.status === "CANCELLED"
                    }
                  />

                  <CheckboxInput
                    checked={!!booking.bring_insurance_card}
                    onPress={(checked) =>
                      handleUpdateBooking({
                        bring_insurance_card: checked,
                      })
                    }
                    label={getText("insurance-card-reminder")}
                    mode={CheckboxInputMode.FLAT}
                    disabled={
                      checkListStatus === "loading" ||
                      type !== "upcoming" ||
                      booking.status === "CANCELLED"
                    }
                  />
                </View>
              )}
            </AppointmentDetailsInfo>
            {!appointmentType ? (
              <LoadingIndicator size={24} />
            ) : (
              <>
                {appointmentType.notes && (
                  <>
                    <View style={styles.notesTitleContainer}>
                      <Text style={styles.notesTitle}>
                        {getText("care-notes")}
                      </Text>
                    </View>
                    <View>
                      <Text style={styles.notes}>{appointmentType.notes}</Text>
                    </View>
                  </>
                )}
              </>
            )}
            {type === "upcoming" && booking.status !== "CANCELLED" && (
              <View style={styles.buttonsContainer}>
                <Button
                  hierarchy="tertiary-gray"
                  size="medium"
                  onPress={handleReschedule}
                  logger={{ id: `appointment-reschedule-${booking.id}` }}
                  loading={patientLoading}
                  disabled={patientLoading}
                >
                  {getText("reschedule")}
                </Button>
                <TouchableOpacity
                  onPress={() => setShowCancelAppointment(true)}
                >
                  <View style={styles.cancelButtonContainer}>
                    <Text style={styles.cancelButtonText}>
                      {getText("cancel-appointment")}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </>
        )}
      </ScreenContainer>
    </>
  );
};

export interface AppointmentDetailsProps {}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: theme.getSpacing(3),
    marginTop: theme.getSpacing(2),
  },
  title: {
    fontSize: 24,
    lineHeight: 32,
    color: theme.palette.gray[900],
    maxWidth: "75%",
  },
  label: {
    paddingHorizontal: theme.getSpacing(1),
    lineHeight: 26,
    marginLeft: theme.getSpacing(2),
    borderRadius: theme.roundness,
    marginTop: theme.getSpacing(0.5),
  },
  labelUpcoming: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[700],
  },
  labelPast: {
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
  },
  description: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
  },
  infoText: {
    fontSize: 16,
    color: theme.palette.gray[700],
  },
  notesTitleContainer: {
    paddingBottom: theme.getSpacing(1),
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    marginVertical: theme.getSpacing(2),
  },
  notesTitle: {
    color: theme.palette.gray[900],
    fontSize: 16,
    lineHeight: 24,
  },
  notes: {
    color: theme.palette.gray[500],
    fontSize: 16,
  },
  buttonsContainer: {
    marginTop: "auto",
  },
  cancelButtonContainer: {
    paddingVertical: theme.getSpacing(1),
    marginVertical: theme.getSpacing(1),
  },
  cancelButtonText: {
    ...theme.fonts.medium,
    color: theme.palette.error[600],
    textAlign: "center",
  },
  checkboxContainer: {
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: theme.getSpacing(2),
  },
  checkboxContentContainer: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    height: 120,
  },
  checkboxLabel: {
    width: "100%",
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    paddingBottom: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
}));
