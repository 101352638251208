import React, { FunctionComponent, PropsWithChildren } from "react";
import { Image, Linking, Platform, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { MAPBOX_ACCESS_TOKEN } from "../../../../apps/mobile/modules/common/constants";
import { getText } from "../../localization/localization";
import { useTheme } from "../../theme";

export const Map: FunctionComponent<PropsWithChildren<MapProps>> = ({
  longitude,
  latitude,
  height = 150,
  width = 150,
  accessToken = MAPBOX_ACCESS_TOKEN,
  zoom = 15,
}) => {
  const theme = useTheme();

  return (
    <View style={{ height, width }}>
      <TouchableOpacity
        testID={MapTestIDs.map}
        onPress={() => openMap(longitude, latitude)}
        accessibilityRole="button"
        accessibilityLabel={getText("open-map")}
      >
        <Image
          style={{
            height,
            width,
            borderRadius: theme.roundness,
            borderWidth: 2,
            borderColor: theme.palette.gray[200],
          }}
          source={{
            uri:
              "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+cc3333(" +
              longitude +
              "," +
              latitude +
              ")/" +
              longitude +
              "," +
              latitude +
              "," +
              zoom +
              ",0/" +
              height +
              "x" +
              width +
              "@2x?access_token=" +
              accessToken,
          }}
        />
      </TouchableOpacity>
    </View>
  );
};

const openMap = (longitude: number, latitude: number) => {
  const scheme = Platform.select({
    ios: "maps:0,0?q=",
    android: "geo:0,0?q=",
  });
  const url = Platform.select({
    ios: scheme + encodeURIComponent(latitude + "," + longitude),
    android: scheme + encodeURIComponent(latitude + "," + longitude),
    default:
      "https://www.google.com/maps/search/?api=1&query=" +
      encodeURIComponent(latitude + "," + longitude),
  });
  Linking.openURL(url);
};

export interface MapProps {
  longitude: number;
  latitude: number;
  height?: number;
  width?: number;
  accessToken?: string;
  zoom?: number;
}

export const MapTestIDs = {
  map: "map",
};
