import { makeStyles, useTheme } from "assets/theme";
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button } from "assets/components/button";
import { View } from "react-native";
import { getText } from "assets/localization/localization";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RefillStackParamList } from "../../RefillNavigation";
import { BottomSheet } from "assets/components/bottom-sheet";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { PickupHandler, PickupProps } from "./PickupProps";
import { Form } from "assets/layout";
import { RadioButtonGroupField } from "assets/components/radio-button-group";
import { useForm } from "assets/form";
import { PickupTestID } from "./PickupTestID";
import { Text } from "assets/components/text";
import { IconButton } from "react-native-paper";
import { CloseIcon } from "assets/icons";
import { useRefillMedicationsStore } from "../../refill-store";

const options = [
  {
    text: getText("yes"),
    value: "yes",
  },
  {
    text: getText("no"),
    value: "no",
  },
];
const PickupBottomSheetComponent: ForwardRefRenderFunction<
  PickupHandler,
  PickupProps
> = (
  {
    allowPickupToday,
    allowPickupUrgent,
    onChange = (value: string) => {},
    handlePickupSubmit = (value: object) => {},
  }: PickupProps,
  ref: ForwardedRef<PickupHandler>
) => {
  const theme = useTheme();
  const styles = useStyles();
  const { selectedLocationId } = useRefillMedicationsStore();

  const methods = useForm({
    defaultValues: {
      pickupPrescriptionToday: "",
      pickupPrescriptionUrgently: "",
    },
  });
  const [showPickupToday, setShowPickupToday] = useState<boolean>(false);
  const [showPickupUrgent, setShowPickupUrgent] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);

  const sheetRef = React.useRef<BottomSheetModal>(null);

  useEffect(() => {
    setShowPickupToday(allowPickupToday);
    setShowPickupUrgent(allowPickupUrgent);
  }, [allowPickupToday, allowPickupUrgent]);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const handleOnPress = () => {
    if (showPickupToday) {
      if (methods.getValues().pickupPrescriptionToday === "") {
        setShowError(true);
        return;
      }
      setShowError(false);
      if (
        methods.getValues().pickupPrescriptionToday === "yes" &&
        showPickupUrgent
      ) {
        setShowPickupUrgent(true);
        setShowPickupToday(false);
        return;
      }
    }
    if (showPickupUrgent) {
      if (methods.getValues().pickupPrescriptionUrgently === "") {
        setShowError(true);
        return;
      }
      setShowError(false);
      setShowPickupUrgent(false);
    }
    handlePickupSubmit(methods.getValues());
    handleBottomSheetDismiss();
  };
  return (
    <BottomSheet
      bottomSheetRef={sheetRef}
      height={"40%"}
      headerContent={
        <View style={styles.bottomSheetViewTitle}>
          <View style={{ flexDirection: "row" }}>
            <View style={styles.bottomSheetViewTitle}>
              <Text style={styles.title}>{getText("pickup")}</Text>
            </View>
            <View style={{ justifyContent: "flex-end" }}>
              <IconButton
                accessibilityLabel={getText("dismiss")}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        </View>
      }
      onDismiss={handleBottomSheetDismiss}
      children={
        <View>
          <Form methods={methods}>
            {showPickupToday && (
              <View>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      label={getText("will-pickup-prescription-today")}
                      rules={{
                        required: getText("field-required"),
                      }}
                      name={"pickupPrescriptionToday"}
                      values={options}
                    />
                  </Form.Column>
                </Form.Row>

                {showError && (
                  <Text style={styles.error}>
                    {getText("select-an-option")}
                  </Text>
                )}
                <Form.Actions>
                  <Button
                    hierarchy="primary"
                    onPress={handleOnPress}
                    testID={PickupTestID.nextButton}
                    logger={{ id: PickupTestID.nextButton }}
                  >
                    {allowPickupUrgent ? getText("next") : getText("save")}
                  </Button>
                </Form.Actions>
              </View>
            )}
            {showPickupUrgent && (
              <View>
                <Form.Row>
                  <Form.Column>
                    <RadioButtonGroupField
                      label={getText("will-pickup-prescription-urgently")}
                      rules={{
                        required: getText("field-required"),
                      }}
                      name={"pickupPrescriptionUrgently"}
                      values={options}
                    />
                  </Form.Column>
                </Form.Row>
                {showError && (
                  <Text style={styles.error}>
                    {getText("select-an-option")}
                  </Text>
                )}
                <Form.Actions>
                  <View style={styles.rowView}>
                    {allowPickupToday && (
                      <View
                        style={{
                          width: "49%",
                          marginRight: theme.getSpacing(1),
                        }}
                      >
                        <Button
                          hierarchy="secondary"
                          onPress={() => {
                            setShowPickupToday(true);
                            setShowPickupUrgent(false);
                          }}
                          testID={PickupTestID.nextButton}
                          logger={{ id: PickupTestID.nextButton }}
                        >
                          {getText("back")}
                        </Button>
                      </View>
                    )}
                    <View
                      style={{
                        width: allowPickupToday ? "49%" : "100%",
                      }}
                    >
                      <Button
                        hierarchy="primary"
                        onPress={handleOnPress}
                        testID={PickupTestID.nextButton}
                        logger={{ id: PickupTestID.nextButton }}
                      >
                        {getText("save")}
                      </Button>
                    </View>
                  </View>
                </Form.Actions>
              </View>
            )}
          </Form>
        </View>
      }
    />
  );
};

export interface PickupTodayBottomSheetProps
  extends NativeStackScreenProps<RefillStackParamList, "refill-review"> {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: "500",
    fontSize: 14,
    marginTop: theme.getSpacing(3),
  },
  rowView: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
  },
  bottomSheetViewTitle: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  error: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 24,
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    color: theme.palette.error[700],
  },
}));

export const PickupBottomSheet = forwardRef<PickupHandler, PickupProps>(
  PickupBottomSheetComponent
);
